import React from 'react';
import MuiDialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTheme } from '@ui/styles/hooks';

import { DialogProps } from './dialog';

const Dialog: React.FC<DialogProps> = ({ children, ...props }) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<MuiDialog
			maxWidth={theme.breakpoints.up('sm') ? 'sm' : undefined}
			fullWidth={true}
			fullScreen={fullScreen}
			{...props}
		>
			{children}
		</MuiDialog>
	);
};

export type { DialogProps };
export default Dialog;
