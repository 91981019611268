import React from 'react';
import Tab, { TabProps } from '@mui/material/Tab';

export type TabLabelProps = Omit<TabProps, 'ref'> & {
	index: number;
	tabId: string;
};
const a11yProps = (tabId: string, index: number) => {
	return {
		id: `${tabId}-tab-${index}`,
		'aria-controls': `${tabId}-tabpanel-${index}`,
	};
};
const TabLabel: React.FC<TabLabelProps> = props => {
	const { tabId, index, ...other } = props;

	return <Tab {...a11yProps(tabId, index)} {...other} />;
};

export default TabLabel;
