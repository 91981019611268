/* eslint-disable indent */
/* eslint-disable react/display-name */
import React from 'react';
import Select, { SelectProps } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import NativeSelect, { NativeSelectProps } from '@mui/material/NativeSelect';

import {
	DropDownProps,
	NativeDropDownProps,
	DropDownOption,
	DefaultRenderValueFN,
} from './types';

const defaultRenderValue: DefaultRenderValueFN =
	(
		options,
		labelExtractor = item => item.label,
		valueExtractor = item => item.value,
		placeholder,
	) =>
	selected => {
		// selected.length prevents an empty string value which is required bc null is not permitted
		if (selected === null || !selected /* || selected?.length === 0*/) {
			return <em>{placeholder || 'Por favor escolha um...'}</em>;
		}

		if (Array.isArray(selected)) {
			const values = options.filter(m =>
				selected.includes(valueExtractor(m)),
			);
			return values.map(m => valueExtractor(m)).join(', ');
		} else if (options.some(m => selected === valueExtractor(m))) {
			return labelExtractor(
				options.find(m => selected === valueExtractor(m)),
			);
		}
	};

const DropDownInput: React.FC<
	SelectProps &
		Pick<DropDownProps, 'options' | 'valueExtractor' | 'labelExtractor'>
> = ({
	options,
	labelExtractor = item => item.label,
	valueExtractor = item => item.value,
	variant,
	...rest
}) => {
	return (
		<Select {...rest} variant={variant || 'outlined'}>
			{options.map((m, idx) => (
				<MenuItem
					key={`dropdown-option-${idx}`}
					value={valueExtractor(m)}
				>
					{labelExtractor(m)}
				</MenuItem>
			))}
		</Select>
	);
};

const DropDown: React.FC<Omit<DropDownProps, 'ref'>> = ({
	labelExtractor = item => item.label,
	valueExtractor = item => item.value,
	options,
	id,
	label,
	error,
	fullWidth,
	helperText,
	renderValue,
	placeholder,
	children,
	variant,
	isRequired,
	...rest
}) => {
	return (
		<FormControl error={error} fullWidth={fullWidth}>
			{label || children ? (
				<FormLabel className="select-label">
					{label || children}
					{isRequired ? '*' : ''}
				</FormLabel>
			) : null}
			<DropDownInput
				{...rest}
				variant={variant || 'outlined'}
				labelExtractor={labelExtractor}
				valueExtractor={valueExtractor}
				options={options}
				displayEmpty
				fullWidth={fullWidth}
				renderValue={
					renderValue ||
					defaultRenderValue(
						options,
						labelExtractor,
						valueExtractor,
						placeholder,
					)
				}
				id={rest.name || id}
			/>
			<FormHelperText id={`dropdown-${id}-helper`}>
				{helperText}
			</FormHelperText>
		</FormControl>
	);
};

const SelectInput: React.FC<
	NativeSelectProps & {
		options: DropDownOption[];
	}
> = ({ options, ...rest }) => {
	return (
		<NativeSelect {...rest}>
			{options.map((m, idx) => (
				<option key={`dropdown-option-${idx}`} value={m.value}>
					{m.label}
				</option>
			))}
		</NativeSelect>
	);
};

const NativeDropDown: React.FC<
	NativeDropDownProps & {
		error?: boolean;
		fullWidth?: boolean;
		isRequired?: boolean;
		label?: React.ReactNode;
		children?: React.ReactNode;
		helperText?: React.ReactNode;
	}
> = ({
	id,
	label,
	error,
	fullWidth,
	helperText,
	children,
	variant,
	sx,
	isRequired,
	...rest
}) => {
	return (
		<FormControl
			sx={sx}
			error={error}
			fullWidth={fullWidth}
			variant={variant}
		>
			<FormLabel className="select-label">
				{label || children}
				{isRequired ? '*' : ''}
			</FormLabel>
			<SelectInput {...rest} variant={variant} fullWidth={fullWidth} />
			<FormHelperText id={`dropdown-${id}-helper`} sx={{ mt: 1 }}>
				{helperText}
			</FormHelperText>
		</FormControl>
	);
};

export type { DropDownProps, DropDownOption, NativeDropDownProps };

export { DropDown as default, DropDownInput, NativeDropDown, SelectInput };
