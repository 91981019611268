import React from 'react';
import Grid, { GridProps } from '@mui/material/Grid';

export type RowProps = Omit<GridProps, 'container' | 'item'>;

const Row: React.ForwardRefRenderFunction<HTMLDivElement, RowProps> = (
	props,
	ref,
) => (
	<Grid container direction={props.direction || 'row'} {...props} ref={ref} />
);

export default React.forwardRef(Row);
