import React from 'react';
import Radio, { RadioProps as MuiRadioProps } from '@mui/material/Radio';
import FormControlLabel, {
	FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import RadioChecked from '@ui/icons/RadioChecked';
import RadioUnChecked from '@ui/icons/RadioUnChecked';

export type RadioProps = MuiRadioProps &
	Omit<FormControlLabelProps, 'control' | 'label'> & {
		noWrap?: boolean;
		error?: boolean;
		fullWidth?: boolean;
		helperText?: string | number | React.ReactElement;
		label?: string | number | React.ReactElement;
	};

const TheRadio: React.FC<React.PropsWithChildren<RadioProps>> = ({
	children,
	label,
	id,
	disabled,
	error,
	fullWidth,
	name,
	color,
	size,
	helperText,
	disableTypography,
	sx,
	...rest
}) => {
	return (
		<FormControl error={error} sx={sx} fullWidth={fullWidth}>
			<FormControlLabel
				{...{
					id: id || name,
					disabled,
					name,
					disableTypography,
					...rest,
				}}
				label={label || (children as any)}
				control={
					<Radio
						{...{ id: id || name, disabled, name, ...rest }}
						color={color || 'primary'}
						size={size || 'small'}
						checkedIcon={<RadioChecked />}
						icon={<RadioUnChecked />}
					/>
				}
			/>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
};

export default TheRadio;
