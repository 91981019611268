import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const Info: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#600160';
	return (
		<svg
			width="7"
			height="18"
			viewBox="0 0 7 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M3.02393 4.69531C2.39372 4.69531 1.85303 4.47331 1.40186 4.0293C0.957845 3.57812 0.73584 3.03743 0.73584 2.40723C0.73584 1.76986 0.957845 1.22917 1.40186 0.785156C1.85303 0.341146 2.39372 0.119141 3.02393 0.119141C3.6613 0.119141 4.20199 0.341146 4.646 0.785156C5.09001 1.22917 5.31201 1.76986 5.31201 2.40723C5.31201 3.03743 5.09001 3.57812 4.646 4.0293C4.20199 4.47331 3.6613 4.69531 3.02393 4.69531ZM4.45264 17.79C3.34261 17.79 2.50472 17.5 1.93896 16.9199C1.37321 16.3398 1.09033 15.484 1.09033 14.3525V7.40234C1.09033 7.08724 1.17985 6.84017 1.35889 6.66113C1.53792 6.4821 1.78499 6.39258 2.1001 6.39258H3.90479C4.21989 6.39258 4.46696 6.4821 4.646 6.66113C4.82503 6.84017 4.91455 7.08724 4.91455 7.40234V14.0195C4.91455 14.1628 4.92887 14.2702 4.95752 14.3418C4.99333 14.4062 5.06852 14.4385 5.18311 14.4385C5.25472 14.4385 5.39079 14.4098 5.59131 14.3525C5.78467 14.2952 5.94222 14.3132 6.06396 14.4062C6.19287 14.4993 6.30029 14.6855 6.38623 14.9648L6.78369 16.3076C6.84814 16.6227 6.85173 16.8411 6.79443 16.9629C6.7443 17.0846 6.6154 17.2064 6.40771 17.3281C5.82764 17.6361 5.17594 17.79 4.45264 17.79Z"
				fill={themeColor}
			/>
		</svg>
	);
};

export default Info;
