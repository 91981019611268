import React from 'react';

import Image, { ImageProps } from '@ui/components/Image';

const Logo: React.FC<Pick<ImageProps, 'width' | 'height'>> = ({
	width,
	height,
}) => (
	<Image
		width={width || 70}
		height={height || 70}
		alt="semina logo"
		src="/icon_logo.png"
	/>
);

export default Logo;
