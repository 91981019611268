import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const BulletDot: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#9A4856';
	return (
		<svg
			width="8"
			height="8"
			viewBox="0 0 8 8"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<circle cx="4" cy="4" r="4" fill={themeColor} />
		</svg>
	);
};

export default BulletDot;
