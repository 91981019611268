import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { CircularProgressProps as IProps } from './circular-progress';

export type CircularProgressProps = IProps;

const TheCircularProgress: React.FC<CircularProgressProps> = ({
	withLabel,
	...props
}) => {
	if (!withLabel) {
		return <CircularProgress {...props} />;
	}
	return (
		<Box sx={{ position: 'relative', display: 'inline-flex' }}>
			<CircularProgress variant="determinate" {...props} />
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Typography
					fontSize={10}
					variant="caption"
					component="div"
					color="text.secondary"
				>
					{`${Math.round(props.value || 0)}%`}
				</Typography>
			</Box>
		</Box>
	);
};

export default TheCircularProgress;
