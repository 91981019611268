import { BrandCode } from '@interfaces/brand';

const brands = [
	{
		label: 'Amamente',
		value: BrandCode.AMAMENTE,
	},
	{
		label: 'Della',
		value: BrandCode.DELLA,
	},
	{
		label: 'KY',
		value: BrandCode.KY,
	},
	{
		label: 'KY Clinical',
		value: BrandCode.KYCLINICAL,
	},
	{
		label: 'NUAÁ',
		value: BrandCode.NUAA,
	},
];
const brandsWithSemina = [
	{
		label: 'Semina',
		value: 'semina',
	},
	...brands,
];

const brandsInstaHandles = [
	{
		handle: 'seminaeducativa',
		brand: 'semina',
	},
	{
		handle: 'kyoficial',
		brand: BrandCode.KY,
	},
	{
		handle: 'kyoficial',
		brand: BrandCode.KYCLINICAL,
	},
	{
		handle: 'sounuaa',
		brand: BrandCode.NUAA,
	},
	// {
	// 	handle: 'Della',
	// 	brand: BrandCode.DELLA,
	// },
	{
		handle: 'amamente',
		brand: BrandCode.AMAMENTE,
	},
];

const getBrandBG = (brandCode?: BrandCode) => {
	switch (brandCode) {
		case BrandCode.AMAMENTE:
			return 'radial-gradient(112.5% 112.5% at 14.64% 100%, #AB8BD4 0%, rgba(181, 130, 136, 0) 100%)';
		case BrandCode.DELLA:
			return 'radial-gradient(99.29% 99.29% at 7.5% 100%, #9F515F 0%, rgba(217, 217, 217, 0) 100%)';
		case BrandCode.KY:
			return 'radial-gradient(108.21% 108.21% at 0% 100%, rgba(51, 57, 113, 0.5) 20.52%, rgba(255, 54, 66, 0) 100%)';
		case BrandCode.KYCLINICAL:
			return 'radial-gradient(84.14% 84.14% at 14.68% 88.79%, rgba(103, 39, 51, 0.48) 42.13%, rgba(255, 239, 183, 0.15) 100%)';
		case BrandCode.NUAA:
			return 'radial-gradient(96.07% 96.07% at 16.07% 100%, rgba(154, 136, 73, 0.8) 0%, rgba(207, 203, 162, 0) 100%)';
		default:
			return 'radial-gradient(84.14% 84.14% at 14.68% 88.79%, rgba(103, 39, 51, 0.48) 42.13%, rgba(255, 239, 183, 0.15) 100%)';
	}
};

const getBrandBGImage = (brandCode?: BrandCode) => {
	switch (brandCode) {
		case BrandCode.KY:
			return `/images/bg_${brandCode}_new.png`;
		case BrandCode.NUAA:
			return `/images/bg_${brandCode}_new.png`;
		default:
			return `/images/bg_${brandCode}.png`;
	}
};

const getLogoSize = (code?: BrandCode, size = 1) => {
	switch (code) {
		case BrandCode.AMAMENTE:
			return { height: 50 * size, width: 110 * size };
		case BrandCode.DELLA:
			return { height: 59 * size, width: 134 * size };
		case BrandCode.KY:
			return { height: 36 * size, width: 111 * size };
		case BrandCode.KYCLINICAL:
			return { height: 50 * size, width: 110 * size };
		case BrandCode.NUAA:
			return { height: 40 * size, width: 106 * size };
		default:
			return { height: 27 * size, width: 150 * size };
	}
};

const getLogo = (code?: BrandCode) => {
	switch (code) {
		case BrandCode.AMAMENTE:
			return `/logo/logo_${code?.toLowerCase()}_color3.png`;
		case BrandCode.DELLA:
			return `/logo/logo_${code?.toLowerCase()}_color.png`;
		case BrandCode.KY:
			return `/logo/logo_${code?.toLowerCase()}_color.png`;
		case BrandCode.KYCLINICAL:
			return `/logo/logo_${code?.toLowerCase()}_color.png`;
		case BrandCode.NUAA:
			return `/logo/logo_${code?.toLowerCase()}_color.png`;
		default:
			return '/logo_semina.png';
	}
};

export {
	brands,
	getBrandBG,
	brandsWithSemina,
	brandsInstaHandles,
	getLogo,
	getLogoSize,
	getBrandBGImage,
};
