import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField, {
	TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import React from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import Box from '@ui/components/Box';

type TextFieldProps = MuiTextFieldProps & {
	isFormControl?: boolean;
	isRequired?: boolean;
};

export type { TextFieldProps as TextInputProps };

const TextInput = React.forwardRef(
	(
		props: TextFieldProps,
		forwardedRef: React.ForwardedRef<HTMLInputElement>,
	) => {
		const [showPassword, setShowPassword] = React.useState(false);

		const handleClickShowPassword = React.useCallback(
			() => setShowPassword(show => !show),
			[],
		);

		const handleMouseDownPassword = React.useCallback(
			(event: React.MouseEvent<HTMLButtonElement>) => {
				event.preventDefault();
			},
			[],
		);
		const {
			error,
			label,
			fullWidth,
			children,
			variant = 'outlined',
			className,
			sx,
			isFormControl = true,
			type,
			InputProps,
			isRequired,
			...rest
		} = props;
		const textField = (
			<>
				<FormLabel className="input-label">
					{label || children}
					{isRequired ? '*' : ''}
				</FormLabel>
				<TextField
					inputRef={forwardedRef}
					error={error}
					fullWidth={fullWidth}
					variant={variant}
					type={
						type === 'password'
							? showPassword
								? 'text'
								: 'password'
							: type
					}
					InputProps={{
						...InputProps,
						endAdornment:
							type === 'password' ? (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
									>
										{showPassword ? (
											<VisibilityOff />
										) : (
											<Visibility />
										)}
									</IconButton>
								</InputAdornment>
							) : (
								InputProps?.endAdornment
							),
					}}
					{...rest}
				/>
			</>
		);
		return isFormControl ? (
			<FormControl
				error={error}
				fullWidth={fullWidth}
				className={className}
				sx={sx}
			>
				{textField}
			</FormControl>
		) : (
			<Box
				display="inline-flex"
				flexDirection="column"
				position="relative"
				minWidth={0}
				padding={0}
				margin={0}
				border={0}
				// verticalAlign="top"
				sx={sx}
				width={fullWidth ? '100%' : undefined}
				className={className}
			>
				{textField}
			</Box>
		);
	},
);

TextInput.displayName = 'TextInput';

export default TextInput;
