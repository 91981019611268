import React from 'react';

import { IconProps } from './props';

const ICal: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 100 100"
			width="50px"
			height="50px"
			{...rest}
		>
			<circle cx="13" cy="29" r="2" fill="#78a0cf" />
			<circle cx="77" cy="13" r="1" fill="#f1bc19" />
			<circle cx="50" cy="50" r="37" fill="#cee1f4" />
			<circle cx="83" cy="15" r="4" fill="#f1bc19" />
			<circle cx="87" cy="24" r="2" fill="#78a0cf" />
			<circle cx="81" cy="76" r="2" fill="#fbcd59" />
			<circle cx="15" cy="63" r="4" fill="#fbcd59" />
			<circle cx="25" cy="87" r="2" fill="#78a0cf" />
			<circle cx="18.5" cy="53.5" r="2.5" fill="#fff" />
			<circle cx="21" cy="67" r="1" fill="#f1bc19" />
			<circle cx="80" cy="34" r="1" fill="#fff" />
			<g>
				<path
					fill="#fdfcee"
					d="M65,28c3.86,0,7,3.14,7,7v30c0,3.86-3.14,7-7,7H35c-3.86,0-7-3.14-7-7V35c0-3.86,3.14-7,7-7H65"
				/>
				<path
					fill="#ee3e54"
					d="M65,28c3.86,0,7,3.14,7,7v6H28v-6c0-3.86,3.14-7,7-7H65"
				/>
				<path
					fill="#fdfcee"
					d="M43.375,36.185c0-0.231,0.048-0.39,0.144-0.477c0.096-0.087,0.256-0.13,0.48-0.13 c0.39,0,0.598,0.132,0.624,0.395c0.026,0.688,0.354,1.032,0.988,1.032c0.678,0,1.017-0.37,1.017-1.109v-3.797h-0.979 c-0.121,0-0.217-0.054-0.287-0.164c-0.071-0.11-0.106-0.241-0.106-0.395c0-0.147,0.033-0.275,0.101-0.38 C45.423,31.053,45.521,31,45.649,31h1.679c0.147,0,0.274,0.036,0.384,0.106c0.108,0.071,0.163,0.167,0.163,0.29v4.5 c0,0.758-0.204,1.315-0.61,1.672c-0.406,0.357-0.939,0.536-1.597,0.536c-0.679,0-1.23-0.172-1.656-0.516 C43.588,37.243,43.375,36.776,43.375,36.185z"
				/>
				<path
					fill="#fdfcee"
					d="M49.125,35.889v-4.504c0-0.128,0.061-0.224,0.183-0.289C49.43,31.033,49.578,31,49.751,31 c0.173,0,0.321,0.033,0.443,0.096c0.123,0.065,0.184,0.161,0.184,0.289v4.504c0,0.738,0.353,1.107,1.059,1.107 c0.707,0,1.06-0.369,1.06-1.107v-4.504c0-0.128,0.061-0.224,0.184-0.289C52.802,31.033,52.951,31,53.124,31 c0.174,0,0.321,0.033,0.444,0.096c0.122,0.065,0.183,0.161,0.183,0.289v4.504c0,0.757-0.21,1.313-0.631,1.67 c-0.421,0.356-0.981,0.535-1.682,0.535c-0.7,0-1.261-0.177-1.681-0.53C49.335,37.211,49.125,36.652,49.125,35.889z"
				/>
				<path
					fill="#fdfcee"
					d="M55,37.607v-6.224c0-0.128,0.062-0.223,0.184-0.288C55.307,31.032,55.456,31,55.63,31 c0.175,0,0.323,0.032,0.446,0.095c0.122,0.065,0.184,0.16,0.184,0.288v5.525h2.363c0.116,0,0.208,0.055,0.276,0.163 C58.966,37.18,59,37.308,59,37.454c0,0.147-0.034,0.275-0.101,0.383C58.83,37.946,58.739,38,58.623,38h-3.071 c-0.149,0-0.278-0.035-0.387-0.105C55.055,37.824,55,37.729,55,37.607z"
				/>
			</g>
			<g>
				<path
					fill="#472b29"
					d="M65,28.4c3.639,0,6.6,2.961,6.6,6.6v30c0,3.639-2.961,6.6-6.6,6.6H35c-3.639,0-6.6-2.961-6.6-6.6V35 c0-3.639,2.961-6.6,6.6-6.6H65 M65,27H35c-4.418,0-8,3.582-8,8v30c0,4.418,3.582,8,8,8h30c4.418,0,8-3.582,8-8V35 C73,30.582,69.418,27,65,27L65,27z"
				/>
				<path
					fill="#472b29"
					d="M59.5,41h-32c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h32c0.276,0,0.5,0.224,0.5,0.5 S59.776,41,59.5,41z"
				/>
				<path
					fill="#472b29"
					d="M63.5,41h-2c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h2c0.276,0,0.5,0.224,0.5,0.5 S63.776,41,63.5,41z"
				/>
				<path
					fill="#472b29"
					d="M72.5,41h-5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h5c0.276,0,0.5,0.224,0.5,0.5 S72.776,41,72.5,41z"
				/>
				<path
					fill="#472b29"
					d="M41,50.398c0-0.309,0.133-0.582,0.398-0.82l2.172-3.169C43.763,46.136,43.984,46,44.232,46 c0.194,0,0.37,0.063,0.53,0.191C44.92,46.319,45,46.482,45,46.683v18.605c0,0.218-0.089,0.392-0.265,0.518 c-0.177,0.128-0.38,0.192-0.609,0.192c-0.248,0-0.455-0.063-0.623-0.192c-0.168-0.126-0.252-0.3-0.252-0.518V49.086l-1.059,1.776 c-0.142,0.219-0.309,0.328-0.503,0.328c-0.195,0-0.358-0.078-0.49-0.232C41.066,50.803,41,50.617,41,50.398z"
				/>
				<path
					fill="#472b29"
					d="M50,50.802v-4.121c0-0.163,0.083-0.318,0.251-0.464C50.418,46.073,50.599,46,50.792,46h9.388 c0.194,0,0.378,0.073,0.555,0.218C60.912,46.364,61,46.519,61,46.682v2.346c0,0.255-0.088,0.519-0.264,0.791l-7.854,15.826 C52.776,65.882,52.582,66,52.3,66c-0.282,0-0.529-0.082-0.741-0.246c-0.212-0.163-0.317-0.372-0.317-0.627 c0-0.073,0.035-0.2,0.105-0.382l7.906-15.607v-1.473h-7.509v3.138c0,0.182-0.088,0.341-0.264,0.477 c-0.176,0.137-0.379,0.205-0.608,0.205c-0.248,0-0.455-0.068-0.621-0.205C50.083,51.143,50,50.984,50,50.802z"
				/>
			</g>
		</svg>
	);
};

export default ICal;
