import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const BreadcrumbSeparator: React.FC<IconProps> = ({
	color,
	htmlColor,
	...rest
}) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.dark) || htmlColor || '#EC008C';
	return (
		<svg
			width="9"
			height="8"
			viewBox="0 0 9 8"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M5.71484 2.28564H6.85766V3.42846H5.71484V2.28564Z"
				fill={themeColor}
			/>
			<path
				d="M4.57227 1.14307H5.71508V2.28589H4.57227V1.14307Z"
				fill={themeColor}
			/>
			<path d="M3.42969 0H4.57251V1.14282H3.42969V0Z" fill={themeColor} />
			<path
				d="M3.42969 6.85693H4.57251V7.99975H3.42969V6.85693Z"
				fill={themeColor}
			/>
			<path
				d="M4.57227 5.71436H5.71508V6.85717H4.57227V5.71436Z"
				fill={themeColor}
			/>
			<path
				d="M5.71484 4.57129H6.85766V5.71411H5.71484V4.57129Z"
				fill={themeColor}
			/>
			<path
				d="M6.8584 3.42871H8.00122V4.57153H6.8584V3.42871Z"
				fill={themeColor}
			/>
			<path
				d="M3.38706 3.42895H4.57215V2.28613H1.14355V3.42895H3.38706Z"
				fill={themeColor}
			/>
			<path
				d="M3.38706 4.57129H1.14355V5.7142H4.57215V4.57129H3.38706Z"
				fill={themeColor}
			/>
			<path
				d="M0.000976562 3.42871H1.1438V4.57153H0.000976562V3.42871Z"
				fill={themeColor}
			/>
		</svg>
	);
};

export default BreadcrumbSeparator;
