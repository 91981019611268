import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const SlideUp: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor = (color && palette[color]?.main) || htmlColor || 'white';
	return (
		<svg
			width="82"
			height="82"
			viewBox="0 0 82 82"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<circle cx="41" cy="41" r="40.5" stroke={themeColor} />
			<path
				d="M41.7522 25L39.6849 25L39.6849 53.042L31.4624 44.8215L29.9995 46.2824L40.7192 57L51.4375 46.2824L49.9753 44.8208L41.7521 53.042L41.7522 25Z"
				fill={themeColor}
			/>
		</svg>
	);
};

export default SlideUp;
