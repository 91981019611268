import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const Dollar: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#600160';
	return (
		<svg
			width="24"
			height="26"
			viewBox="0 0 24 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M12 25.5151C18.6169 25.5151 24 19.8057 24 12.7878C24 5.76993 18.6169 0.0605469 12 0.0605469C5.38313 0.0605469 0 5.76993 0 12.7878C0 19.8057 5.38313 25.5151 12 25.5151ZM12 2.7306C17.2287 2.7306 21.4825 7.2422 21.4825 12.7878C21.4825 18.3334 17.2287 22.845 12 22.845C6.77127 22.845 2.51748 18.3334 2.51748 12.7878C2.51748 7.2422 6.77127 2.7306 12 2.7306Z"
				fill={themeColor}
			/>
			<path
				d="M8.77097 18.4561C9.52454 18.979 10.0991 19.2219 10.7527 19.328V19.5633C10.7527 20.3006 11.3163 20.8983 12.0115 20.8983C12.7067 20.8983 13.2702 20.3006 13.2702 19.5633V19.1847C14.7367 18.6623 15.6742 17.2767 15.8909 15.9086C16.1902 14.0199 15.2258 12.3141 13.491 11.6638C12.6112 11.3339 11.6405 10.9375 11.1122 10.498C11.0312 10.4306 10.9712 10.2115 11.0198 9.93838C11.0439 9.80238 11.1557 9.34785 11.5784 9.21283C12.653 8.86955 13.1348 9.50574 13.5591 9.74124C14.1753 10.0828 14.9358 9.83015 15.2579 9.1767C15.5721 8.53918 15.3528 7.75569 14.7698 7.40075C14.3383 7.08996 13.9361 6.80417 13.2704 6.60579V6.39948C13.2704 5.66219 12.7068 5.06445 12.0116 5.06445C11.3165 5.06445 10.7529 5.66219 10.7529 6.39948V6.6923C8.31757 7.563 7.72328 11.0722 9.55869 12.5992C10.3714 13.2751 11.5258 13.7591 12.6521 14.1813C13.401 14.462 13.4766 15.0349 13.4083 15.4661C13.3123 16.072 12.8317 16.7267 12.0032 16.7325C11.0777 16.7408 10.8365 16.6989 10.1492 16.2219C9.56742 15.8182 8.78733 15.9911 8.40669 16.6083C8.02613 17.2251 8.18918 18.0524 8.77097 18.4561Z"
				fill={themeColor}
			/>
		</svg>
	);
};

export default Dollar;
