import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const OutLookCalendar: React.FC<IconProps> = ({
	color,
	htmlColor,
	...rest
}) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#1976d2';
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 48 48"
			width="48px"
			height="48px"
			{...rest}
		>
			<path
				fill={themeColor}
				d="M28,13h14.533C43.343,13,44,13.657,44,14.467v19.066C44,34.343,43.343,35,42.533,35H28V13z"
			/>
			<rect width="14" height="15.542" x="28" y="17.958" fill="#fff" />
			<polygon fill={themeColor} points="27,44 4,39.5 4,8.5 27,4" />
			<path
				fill="#fff"
				d="M15.25,16.5c-3.176,0-5.75,3.358-5.75,7.5s2.574,7.5,5.75,7.5S21,28.142,21,24 S18.426,16.5,15.25,16.5z M15,28.5c-1.657,0-3-2.015-3-4.5s1.343-4.5,3-4.5s3,2.015,3,4.5S16.657,28.5,15,28.5z"
			/>
			<rect
				width="2.7"
				height="2.9"
				x="28.047"
				y="29.737"
				fill={themeColor}
			/>
			<rect
				width="2.7"
				height="2.9"
				x="31.448"
				y="29.737"
				fill={themeColor}
			/>
			<rect
				width="2.7"
				height="2.9"
				x="34.849"
				y="29.737"
				fill={themeColor}
			/>
			<rect
				width="2.7"
				height="2.9"
				x="28.047"
				y="26.159"
				fill={themeColor}
			/>
			<rect
				width="2.7"
				height="2.9"
				x="31.448"
				y="26.159"
				fill={themeColor}
			/>
			<rect
				width="2.7"
				height="2.9"
				x="34.849"
				y="26.159"
				fill={themeColor}
			/>
			<rect
				width="2.7"
				height="2.9"
				x="38.25"
				y="26.159"
				fill={themeColor}
			/>
			<rect
				width="2.7"
				height="2.9"
				x="28.047"
				y="22.706"
				fill={themeColor}
			/>
			<rect
				width="2.7"
				height="2.9"
				x="31.448"
				y="22.706"
				fill={themeColor}
			/>
			<rect
				width="2.7"
				height="2.9"
				x="34.849"
				y="22.706"
				fill={themeColor}
			/>
			<rect
				width="2.7"
				height="2.9"
				x="38.25"
				y="22.706"
				fill={themeColor}
			/>
			<rect
				width="2.7"
				height="2.9"
				x="31.448"
				y="19.112"
				fill={themeColor}
			/>
			<rect
				width="2.7"
				height="2.9"
				x="34.849"
				y="19.112"
				fill={themeColor}
			/>
			<rect
				width="2.7"
				height="2.9"
				x="38.25"
				y="19.112"
				fill={themeColor}
			/>
		</svg>
	);
};

export default OutLookCalendar;
