import Container from '@web/components/layout/Container';

const ContainerConditon: React.FC<
	React.PropsWithChildren<{ noContainer?: boolean }>
> = ({ noContainer, children }) => {
	if (!noContainer) {
		return <Container maxWidth="xl">{children}</Container>;
	}
	return <>{children}</>;
};

export default ContainerConditon;
