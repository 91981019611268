import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const RequestSubmitted: React.FC<IconProps> = ({
	color,
	htmlColor,
	...rest
}) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#FFFFFF';
	return (
		<svg
			width="117"
			height="117"
			viewBox="0 0 117 117"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M58.5 117C90.8087 117 117 90.8087 117 58.5C117 26.1913 90.8087 0 58.5 0C26.1913 0 0 26.1913 0 58.5C0 90.8087 26.1913 117 58.5 117Z"
				fill="url(#paint0_linear)"
				fillOpacity="0.35"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M58.5 95C78.6584 95 95 78.6584 95 58.5C95 38.3416 78.6584 22 58.5 22C38.3416 22 22 38.3416 22 58.5C22 78.6584 38.3416 95 58.5 95Z"
				fill="url(#paint1_linear)"
			/>
			<path
				d="M77.8166 44.3176L76.2582 42.7595C75.7685 42.2695 75.1155 42 74.4185 42C73.7215 42 73.0681 42.2695 72.5784 42.7595L50.6798 64.6526L36.9979 50.9735C36.5082 50.4843 35.8549 50.2143 35.1578 50.2143C34.4612 50.2143 33.8083 50.4843 33.3182 50.9735L31.7593 52.5312C31.2692 53.0219 31 53.6755 31 54.3716C31 55.0681 31.2692 55.7212 31.7593 56.2112L47.207 71.6545C47.2275 71.6835 47.2492 71.7106 47.2743 71.7354L48.8332 73.2679C49.3229 73.756 49.9762 74 50.6787 74H50.6868C51.3842 74 52.0376 73.756 52.5265 73.2679L54.0857 71.7218C54.1109 71.6971 54.1321 71.6766 54.1449 71.6557L77.8163 47.9922C78.8316 46.9794 78.8316 45.3316 77.8166 44.3176Z"
				fill={themeColor}
			/>
			<defs>
				<linearGradient
					id="paint0_linear"
					x1="-24.5997"
					y1="47.9169"
					x2="47.5141"
					y2="139.62"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#69E4A2" />
					<stop offset="1" stopColor="#2BBA8F" />
				</linearGradient>
				<linearGradient
					id="paint1_linear"
					x1="6.65146"
					y1="51.8969"
					x2="51.6456"
					y2="109.113"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#69E4A2" />
					<stop offset="1" stopColor="#2BBA8F" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default RequestSubmitted;
