import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const Trash: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#634B99';
	return (
		<svg
			width="16"
			height="22"
			viewBox="0 0 16 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M13.4844 2.56424H12.2818L12.2155 2.13097C12.0254 0.893063 10.9776 0 9.72644 0H6.28681C5.03564 0 3.98784 0.893063 3.79773 2.13097L3.73141 2.56424H2.52003C1.1318 2.56424 0 3.71373 0 5.10196V7.75462C0 8.17021 0.336004 8.48853 0.751588 8.48853H1.1937V18.6924C1.1937 20.0807 2.32992 21.2213 3.71815 21.2213H12.2818C13.6701 21.2213 14.8063 20.0807 14.8063 18.6924V8.48853H15.2484C15.664 8.48853 16 8.17021 16 7.75462V5.10196C16.0044 3.71373 14.8726 2.56424 13.4844 2.56424ZM5.2788 2.36087C5.35396 1.86129 5.77839 1.50318 6.28239 1.50318H9.72201C10.226 1.50318 10.6504 1.86129 10.7256 2.36087L10.7566 2.56424H5.24343L5.2788 2.36087ZM1.50318 5.10196C1.50318 4.54048 1.95855 4.06742 2.52003 4.06742H13.4844C14.0459 4.06742 14.5012 4.54048 14.5012 5.10196V6.98535H1.50318V5.10196ZM13.3075 18.6924C13.3075 19.2539 12.8433 19.7181 12.2863 19.7181H3.71815C3.15667 19.7181 2.69688 19.2539 2.69688 18.6924V8.48853H13.3075V18.6924Z"
				fill={themeColor}
			/>
		</svg>
	);
};

export default Trash;
