import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const Done: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#634B99';
	return (
		<svg
			width="20"
			height="14"
			viewBox="0 0 20 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M19.68 0.974471L19.0249 0.319349C18.819 0.113333 18.5446 0 18.2515 0C17.9585 0 17.6839 0.113333 17.478 0.319349L8.27268 9.52454L2.5213 3.77301C2.31545 3.56731 2.04081 3.45382 1.7478 3.45382C1.45496 3.45382 1.18049 3.56731 0.974472 3.77301L0.319187 4.42796C0.113171 4.63431 0 4.9091 0 5.20178C0 5.49463 0.113171 5.76926 0.319187 5.97528L6.81285 12.4686C6.82146 12.4808 6.83057 12.4922 6.84114 12.5026L7.49642 13.147C7.70228 13.3522 7.97691 13.4548 8.27219 13.4548H8.27561C8.56878 13.4548 8.84341 13.3522 9.04894 13.147L9.70439 12.4969C9.71496 12.4865 9.7239 12.4779 9.72927 12.4691L19.6798 2.51951C20.1067 2.09366 20.1067 1.40081 19.68 0.974471Z"
				fill={themeColor}
			/>
		</svg>
	);
};

export default Done;
