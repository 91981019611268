import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const Close: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#60005F';
	return (
		<svg
			width="11"
			height="12"
			viewBox="0 0 11 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M1.26465 1.55029L10.0313 10.3478"
				stroke={themeColor}
				strokeWidth="2"
			/>
			<path
				d="M1.24902 10.3325L10.0466 1.56592"
				stroke={themeColor}
				strokeWidth="2"
			/>
		</svg>
	);
};

export default Close;
