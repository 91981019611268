import React from 'react';
import IconButton from '@mui/material/IconButton';

import { IProps } from './icon-button';

export type IconButtonProps = IProps;

const TheIconButton: React.ForwardRefRenderFunction<
	HTMLButtonElement,
	IconButtonProps
> = ({ size, ...props }, ref) => {
	return (
		<IconButton
			{...{
				...props,
				size: size || 'large',
				ref,
			}}
		/>
	);
};

export default React.forwardRef(TheIconButton);
