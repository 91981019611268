import React from 'react';

import { styled } from '@ui/styles';
import Box, { BoxProps } from '@ui/components/Box';
import { Color } from '@ui/index';

export type TabPanelProps = Omit<BoxProps, 'color' | 'ref' | 'id'> & {
	index: number;
	tabId: string;
	value: number;
	color?: Color;
};

const TabPanel: React.FC<TabPanelProps> = props => {
	const { children, value, index, tabId, ...other } = props;
	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`${tabId}-tabpanel-${index}`}
			aria-labelledby={`${tabId}-tab-${index}`}
			{...other}
		>
			{/* {value === index ? <>{children}</> : null} */}
			{children}
		</Box>
	);
};

const TabPanelImpl = styled(TabPanel, {
	shouldForwardProp: prop => prop !== 'color',
})(({ theme, color }) => ({
	backgroundColor: color ? theme.palette[color].main : undefined,
	'& > .MuiTypography-root': {
		color: color ? theme.palette[color].contrastText : undefined,
	},
}));

export default TabPanelImpl;
