import React from 'react';
import MuiFab, { FabProps as MuiFabProps } from '@mui/material/Fab';

import { Add } from '@ui/icons';

type FabProps = MuiFabProps & {
	floating?: 'bottom-right' | false;
	icon?: 'add';
};

const fabStyles = {
	'bottom-right': {
		position: 'fixed',
		bottom: 16,
		right: 16,
	},
};

const icons = { add: <Add /> };

const Fab: React.ForwardRefRenderFunction<HTMLButtonElement, FabProps> = (
	{ floating = 'bottom-right', icon = 'add', ...props },
	ref,
) => (
	<MuiFab
		{...props}
		ref={ref}
		color="primary"
		sx={floating ? fabStyles[floating] : undefined}
	>
		{icons[icon]}
	</MuiFab>
);

export type { FabProps };
export default React.forwardRef(Fab);
