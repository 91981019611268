import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const Scope: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#EC008C';
	return (
		<svg
			width="42"
			height="42"
			viewBox="0 0 42 42"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<circle cx="21" cy="21" r="21" fill="white" />
			<path
				d="M27.694 20.6723C26.6505 20.6723 25.6498 21.0868 24.912 21.8245C24.1741 22.5625 23.7596 23.5631 23.7596 24.6067V27.0656C23.7596 27.6743 23.5179 28.2581 23.0874 28.6884C22.657 29.1188 22.0733 29.3606 21.4645 29.3606H20.4262C19.8177 29.3606 19.2339 29.1188 18.8034 28.6884C18.373 28.2581 18.1312 27.6743 18.1312 27.0656V21.0547C19.6572 20.8557 21.0588 20.1083 22.0742 18.952C23.0898 17.7957 23.65 16.3095 23.6504 14.7705V11H22.0109V14.7705C21.8895 16.3598 20.9709 17.7791 19.5707 18.5407C18.1706 19.3022 16.4797 19.3022 15.0797 18.5407C13.6795 17.7791 12.7609 16.3598 12.6394 14.7705V11H11V14.7705C11.0004 16.3095 11.5605 17.7957 12.5762 18.952C13.5916 20.1082 14.9931 20.8557 16.5191 21.0547V27.0656C16.5191 28.1043 16.9299 29.1009 17.6619 29.838C18.3937 30.575 19.3874 30.9928 20.4261 31H21.4919C22.5306 30.9928 23.5243 30.575 24.2561 29.838C24.9882 29.1009 25.3989 28.1043 25.3989 27.0656V24.6067C25.4818 23.8506 25.9339 23.1847 26.6058 22.8285C27.2778 22.4725 28.0827 22.4725 28.7547 22.8285C29.4269 23.1847 29.8788 23.8506 29.9617 24.6067V28.9509H31.6011V24.6067C31.6011 23.5678 31.1904 22.5712 30.4583 21.8343C29.7265 21.0971 28.7326 20.6795 27.6939 20.6723H27.694Z"
				fill={themeColor}
			/>
		</svg>
	);
};

export default Scope;
