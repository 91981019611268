import React from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Rating from '@mui/material/Rating';

import { styled } from '@ui/styles';

import { RatingProps as IProps } from './rating';

export type RatingProps = IProps;

const RatingIndicator = styled((props: Omit<RatingProps, 'ref' | 'label'>) => (
	<Rating {...props} />
))(({ theme, size }) => ({
	'& .MuiRating-icon > svg': {
		height: size ? undefined : theme.spacing(4.2),
		width: size ? undefined : theme.spacing(4.2),
	},
	'& .MuiRating-iconEmpty > svg': {
		height: size ? undefined : theme.spacing(4.2),
		width: size ? undefined : theme.spacing(4.2),
	},
}));

const Ratings: React.FC<React.PropsWithChildren<Omit<RatingProps, 'ref'>>> = ({
	label,
	error,
	fullWidth,
	helperText,
	sx,
	children,
	isRequired,
	...rest
}) => {
	return (
		<FormControl error={error} sx={sx} fullWidth={fullWidth}>
			<FormLabel error={error}>
				{label || children}
				{isRequired ? '*' : ''}
			</FormLabel>
			<RatingIndicator {...rest} />
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
};
export { Ratings as default, RatingIndicator };
