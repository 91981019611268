import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const Marker: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#E1E1E1';
	return (
		<svg
			width="12"
			height="15"
			viewBox="0 0 12 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M5.60073 0.189476C2.81924 0.287073 0.508502 2.45715 0.241548 5.22717C0.187009 5.78117 0.218584 6.31795 0.316181 6.83176C0.316181 6.83176 0.324792 6.89204 0.353497 7.00686C0.439611 7.39151 0.568782 7.76467 0.729529 8.11487C1.28927 9.44103 2.58386 11.6599 5.48878 14.074C5.66675 14.2232 5.92796 14.2232 6.1088 14.074C9.01372 11.6628 10.3083 9.4439 10.8709 8.112C11.0345 7.7618 11.1608 7.39151 11.247 7.00399C11.2728 6.89204 11.2843 6.82889 11.2843 6.82889C11.3503 6.48444 11.3847 6.13137 11.3847 5.76969C11.3847 2.6179 8.77547 0.0775277 5.60073 0.189476ZM5.79879 8.54831C4.3004 8.54831 3.08619 7.3341 3.08619 5.83571C3.08619 4.33732 4.3004 3.12311 5.79879 3.12311C7.29718 3.12311 8.51139 4.33732 8.51139 5.83571C8.51139 7.3341 7.29718 8.54831 5.79879 8.54831Z"
				fill={themeColor}
			/>
		</svg>
	);
};

export default Marker;
