import React from 'react';
import Alert from '@mui/material/Alert';

import { AlertProps as IProps, AlertColor } from './alert';

export type AlertProps = IProps;
export type { AlertColor };
const TheAlert: React.FC<AlertProps> = ({ variant, ...props }) => {
	return <Alert {...props} variant={variant || 'filled'} />;
};
export default TheAlert;
