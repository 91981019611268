import React, { useEffect, useImperativeHandle, useRef } from 'react';
import { BrowserQRCodeReader, IScannerControls } from '@zxing/browser';

import { UseQrReaderHook } from './types';
import { isMediaDevicesSupported, isValidType } from './utils';

export const useQrReader: UseQrReaderHook = ({
	scanDelay: delayBetweenScanAttempts,
	constraints: video,
	onResult,
	controlRef,
	videoId,
}) => {
	const codeReader = new BrowserQRCodeReader(undefined, {
		delayBetweenScanAttempts,
	});
	const controlsRef = useRef<IScannerControls>();
	const handleScan = React.useCallback(() => {
		if (
			!isMediaDevicesSupported() &&
			isValidType(onResult, 'onResult', 'function')
		) {
			const message =
				'MediaDevices API has no support for your browser. You can fix this by running "npm i webrtc-adapter"';
			if (onResult) {
				onResult(null, new Error(message), codeReader);
			}
		}

		if (isValidType(video, 'constraints', 'object')) {
			codeReader
				.decodeFromConstraints(
					{ video },
					videoId,
					(result, error, controls) => {
						controlsRef.current = controls;
						if (isValidType(onResult, 'onResult', 'function')) {
							if (onResult) {
								onResult(result, error, codeReader);
							}
						}
					},
				)
				.catch((error: Error) => {
					if (isValidType(onResult, 'onResult', 'function')) {
						if (onResult) {
							onResult(null, error, codeReader);
						}
					}
				});
		}
	}, []);
	useImperativeHandle(
		controlRef,
		() => ({
			stop: () => {
				controlsRef.current?.stop();
				BrowserQRCodeReader.releaseAllStreams();
			},
			start: () => {
				handleScan();
			},
			flashOn: () => {
				if (controlsRef.current?.switchTorch) {
					controlsRef.current?.switchTorch(true);
				}
			},
			flashOff: () => {
				if (controlsRef.current?.switchTorch) {
					controlsRef.current?.switchTorch(false);
				}
			},
		}),
		[controlRef.current],
	);

	useEffect(() => {
		handleScan();
	}, []);
};
