import React from 'react';
import dynamic from 'next/dynamic';

import Box from '@ui/components/Box';

import { BrandCode } from '@interfaces/index';

const Default = dynamic(
	() => import('@common-lib/modules/landing/components/Background'),
);
const KYClinical = dynamic(
	() =>
		import(
			'@common-lib/modules/brands/components/ky-clinical/ContentBackground'
		),
);
const KY = dynamic(
	() => import('@common-lib/modules/brands/components/ky/ContentBackground'),
);
const Nuaa = dynamic(
	() =>
		import('@common-lib/modules/brands/components/nuaa/ContentBackground'),
);
const Della = dynamic(
	() =>
		import('@common-lib/modules/brands/components/della/ContentBackground'),
);
const Amamente = dynamic(
	() =>
		import(
			'@common-lib/modules/brands/components/amamente/ContentBackground'
		),
);

const Background: React.FC<{ brandCode?: BrandCode | null }> = ({
	brandCode,
}) => {
	switch (brandCode) {
		case BrandCode.KYCLINICAL:
			return <KYClinical />;
		case BrandCode.KY:
			return <KY />;
		case BrandCode.AMAMENTE:
			return <Amamente />;
		case BrandCode.DELLA:
			return <Della />;
		case BrandCode.NUAA:
			return <Nuaa />;
		default:
			return <Default />;
	}
};

const BrandBackground: React.FC<{ brandCode?: BrandCode | null }> = ({
	brandCode,
}) => {
	return (
		<Box display="flex" justifyContent="center" flexDirection="row">
			<Background brandCode={brandCode} />
		</Box>
	);
};

export default BrandBackground;
