import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const SlideUp: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor = (color && palette[color]?.main) || htmlColor || 'white';
	return (
		<svg
			width="82"
			height="82"
			viewBox="0 0 82 82"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<circle cx="41" cy="41" r="40.5" stroke={themeColor} />
			<path
				d="M39.6853 57L41.7526 57L41.7526 28.958L49.9751 37.1785L51.438 35.7176L40.7183 25L30 35.7176L31.4622 37.1792L39.6854 28.958L39.6853 57Z"
				fill={themeColor}
			/>
		</svg>
	);
};

export default SlideUp;
