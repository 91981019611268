import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const RemoveCircle: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#CC1D1D';
	return (
		<svg
			width="100px"
			height="100px"
			viewBox="0 0 100 100"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...rest}
		>
			<g
				id="Page-1"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g id="removeCircle">
					<circle
						id="Oval"
						fill={themeColor}
						cx="50"
						cy="50"
						r="50"
					/>
					<path
						d="M82.0703853,45.02168 C84.8438451,45.02168 87.0921786,47.2700135 87.0921786,50.0434733 C87.0921786,52.7439474 84.9606215,54.9465697 82.2882202,55.060627 L82.0703853,55.0652667 L17.9296147,55.0652667 C15.1561549,55.0652667 12.9078214,52.8169332 12.9078214,50.0434733 C12.9078214,47.3429992 15.0393785,45.140377 17.7117798,45.0263197 L17.9296147,45.02168 L82.0703853,45.02168 Z"
						id="Line"
						fill="white"
						fillRule="nonzero"
						transform="translate(50.000000, 50.043473) rotate(45.000000) translate(-50.000000, -50.043473) "
					/>
					<path
						d="M82.0703853,45.02168 C84.8438451,45.02168 87.0921786,47.2700135 87.0921786,50.0434733 C87.0921786,52.7439474 84.9606215,54.9465697 82.2882202,55.060627 L82.0703853,55.0652667 L17.9296147,55.0652667 C15.1561549,55.0652667 12.9078214,52.8169332 12.9078214,50.0434733 C12.9078214,47.3429992 15.0393785,45.140377 17.7117798,45.0263197 L17.9296147,45.02168 L82.0703853,45.02168 Z"
						id="Line"
						fill="white"
						fillRule="nonzero"
						transform="translate(50.000000, 50.043473) scale(-1, 1) rotate(45.000000) translate(-50.000000, -50.043473) "
					/>
				</g>
			</g>
		</svg>
	);
};
export default RemoveCircle;
