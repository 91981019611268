import * as React from 'react';

import { useQrReader } from './hooks';
import { QrReaderProps } from './types';

export const styles = {
	container: {
		width: '100%',
		paddingTop: '100%',
		overflow: 'hidden',
		position: 'relative',
	},
	video: {
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		display: 'block',
		overflow: 'hidden',
		position: 'absolute',
		transform: undefined,
	},
};

export type { QrReaderProps };

const QrReader: React.FC<QrReaderProps> = ({
	videoContainerStyle,
	containerStyle,
	videoStyle,
	ViewFinder,
	className,
	onResult,
	videoId = 'video',
	scanDelay = 500,
	constraints = {
		facingMode: 'user',
	},
	controlRef,
}) => {
	useQrReader({
		constraints,
		scanDelay,
		onResult,
		videoId,
		controlRef,
	});

	return (
		<section className={className} style={containerStyle}>
			<div
				style={{
					...styles.container,
					...videoContainerStyle,
				}}
			>
				{!!ViewFinder && <ViewFinder />}
				<video
					muted
					id={videoId}
					style={{
						...styles.video,
						...videoStyle,
						transform:
							constraints?.facingMode === 'user' && 'scaleX(-1)',
					}}
				/>
			</div>
		</section>
	);
};

export default QrReader;
