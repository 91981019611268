import React from 'react';

import Text, { TextProps } from '@ui/components/Text';

export type TextPillProps = TextProps;

const TextPill: React.FC<
	TextProps & { mini?: boolean; fullWidth?: boolean }
> = ({
	sx,
	color,
	variant,
	className,
	bgcolor,
	mini = true,
	fullWidth = false,
	...props
}) => {
	return (
		<Text
			color={color || 'warning'}
			variant={variant || 'caption'}
			className={`${className} ${mini ? 'mini-info' : 'large-info'}`}
			sx={{
				mb: 2,
				py: 0.5,
				px: 1.5,
				bgcolor: bgcolor || '#FEF6EA',
				borderRadius: 2,
				width: fullWidth ? '100%' : 'max-content',
				fontSize: '0.9rem',
				...sx,
			}}
			{...props}
		/>
	);
};
export default TextPill;
