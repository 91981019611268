import Container, { ContainerProps } from '@ui/components/Container';
import { styled } from '@ui/styles';

const TheContainer = styled(Container)<
	ContainerProps & { component?: React.ElementType }
>(({ theme }) => ({
	paddingLeft: '0px !important',
	paddingRight: '0px !important',
	overflow: 'visible',
	position: 'relative',
}));

export default TheContainer;
