import React from 'react';
import Avatar from '@mui/material/Avatar';

import { styled } from '@ui/styles';

import { AvatarProps as IProps } from './avatar';

export type AvatarProps = IProps;
const sizes = {
	tiny: { width: 10, height: 10, fontSize: '0.5rem', fontWeight: 'bold' },
	small: { width: 24, height: 24, fontSize: '1rem', fontWeight: 'bold' },
	medium: { width: 32, height: 32, fontSize: '1.25rem', fontWeight: 'bold' },
	large: { width: 50, height: 50, fontSize: '1.5rem', fontWeight: 'bold' },
	xlarge: { width: 72, height: 72, fontSize: '2rem', fontWeight: 'bold' },
};

const stringToColor = (string: string) => {
	let hash = 0;
	let i;

	/* eslint-disable no-bitwise */
	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = '#';

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.substring(
			`00${value.toString(16)}`.length - 2,
		);
	}
	/* eslint-enable no-bitwise */

	return color;
};

const stringAvatar = (name: string, sx: any, children: any) => {
	const names = name.split(' ');
	const fName = names[0] || '';
	const lName = names[1] || '';
	return {
		sx: {
			...(sx || {}),
			// bgcolor: stringToColor(name),
			bgcolor: '#C4878F',
		},
		// children:
		// 	children || `${fName[0] || ''}${lName[0] || ''}`.toUpperCase(),
		children: children || `${fName[0] || ''}`.toUpperCase(),
	};
};
const TheAvatar = styled(
	({ sx, children, alt, ...props }: AvatarProps) => (
		<Avatar {...props} {...stringAvatar(alt, sx, children)} />
	),
	{
		shouldForwardProp: prop => prop !== 'size',
	},
)(({ size }) => ({
	...sizes[size],
}));

export default TheAvatar;
