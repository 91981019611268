import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const KYClinical: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor = (color && palette[color]?.main) || htmlColor || 'white';
	return (
		<svg
			width="102"
			height="47"
			viewBox="0 0 102 47"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M16.0354 16.7989C16.0354 16.7989 15.8561 16.6746 15.8421 16.4976C15.8561 16.3206 16.0354 16.1963 16.0354 16.1963C16.0354 16.1963 40.2291 0.810367 40.6368 0.438472C41.0434 0.0665766 40.6368 0.0118861 40.6368 0.0118861C40.6368 0.0118861 31.6852 -0.0159563 30.934 0.0118861C30.1829 0.0387342 29.9014 0.204794 29.9014 0.204794C29.9014 0.204794 8.58705 14.297 8.11834 14.5446C8.06025 14.5755 8.00416 14.5983 7.95108 14.6162C7.71873 14.666 7.32513 14.6858 7.32513 14.2394V0.342018C7.32513 0.185901 7.17991 0.0576273 7.00164 0.0576273H0.323492C0.145221 0.0576273 0 0.185901 0 0.342018V32.6005C0 32.7576 0.145221 32.8848 0.323492 32.8848H7.00064C7.17891 32.8848 7.32413 32.7576 7.32413 32.6005V18.7558C7.32413 18.3093 7.71773 18.3282 7.95008 18.3779C8.00316 18.3958 8.05925 18.4187 8.11734 18.4495C8.58705 18.6971 29.9004 32.7894 29.9004 32.7894C29.9004 32.7894 30.1829 32.9545 30.933 32.9823C31.6841 33.0091 40.6358 32.9823 40.6358 32.9823C40.6358 32.9823 41.0424 32.9276 40.6358 32.5557C40.2291 32.1838 16.0344 16.7979 16.0344 16.7979"
				fill={themeColor}
			/>
			<path
				d="M41.5531 23.2704L31.5599 16.811C31.3386 16.6688 31.3396 16.3774 31.5619 16.2362L41.5531 9.80067C41.6973 9.70918 41.8916 9.71117 42.0328 9.80564L52.031 16.2392C52.2453 16.3814 52.2443 16.6648 52.029 16.806L42.0328 23.2665C41.8906 23.3599 41.6963 23.3609 41.5531 23.2684"
				fill={themeColor}
			/>
			<path
				d="M99.8228 0.0807311C99.8228 0.0807311 90.746 0.0121194 90.0259 0.0210688C89.3058 0.0300181 88.8992 0.32833 88.8992 0.32833C88.8992 0.32833 71.9354 11.8879 71.5128 12.1365C71.0901 12.3841 70.7927 12.1365 70.7927 12.1365C70.7927 12.1365 54.1364 0.893133 53.5835 0.443677C53.0307 -0.00577927 52.541 0.0220632 52.541 0.0220632C52.541 0.0220632 42.7751 0.0220632 42.4626 0.0210688C42.1492 0.0200744 42.3585 0.297504 42.3585 0.297504C42.3585 0.297504 66.396 15.7113 66.9388 16.1697C67.4817 16.6281 67.4817 16.9771 67.4817 16.9771V32.6017C67.4817 32.7588 67.6269 32.8861 67.8052 32.8861H74.4823C74.6606 32.8861 74.8058 32.7588 74.8058 32.6017V17.4355C74.8058 16.3347 75.7242 15.8395 75.7242 15.8395C75.7242 15.8395 99.5113 0.742983 99.8248 0.425778C100.137 0.109568 99.8248 0.0817255 99.8248 0.0817255"
				fill={themeColor}
			/>
			<path
				d="M99.96 6.01299C98.8353 6.01299 97.9209 5.20854 97.9209 4.21914C97.9209 3.22974 98.8363 2.42529 99.96 2.42529C101.084 2.42529 102 3.22974 102 4.21914C102 5.20854 101.085 6.01299 99.96 6.01299ZM99.96 2.64604C98.9735 2.64604 98.1703 3.35205 98.1703 4.21914C98.1703 5.08623 98.9735 5.79224 99.96 5.79224C100.947 5.79224 101.75 5.08623 101.75 4.21914C101.75 3.35205 100.947 2.64604 99.96 2.64604Z"
				fill={themeColor}
			/>
			<path
				d="M99.0776 3.11241C99.2529 3.08556 99.5163 3.06567 99.7867 3.06567C100.157 3.06567 100.409 3.11937 100.581 3.24665C100.722 3.35106 100.802 3.50817 100.802 3.70008C100.802 3.99243 100.577 4.19031 100.338 4.26688V4.27682C100.521 4.33748 100.631 4.49558 100.696 4.71335C100.776 4.99476 100.844 5.25628 100.898 5.34378H100.414C100.376 5.27716 100.315 5.0922 100.242 4.8108C100.17 4.51547 100.048 4.421 99.7807 4.41504H99.5443V5.34378H99.0786V3.1134L99.0776 3.11241ZM99.5433 4.11175H99.8218C100.138 4.11175 100.337 3.96359 100.337 3.73886C100.337 3.49027 100.138 3.37691 99.8328 3.37691C99.6846 3.37691 99.5884 3.38686 99.5433 3.3968V4.11175Z"
				fill={themeColor}
			/>
			<path
				d="M5.90285 38.6135L5.84877 39.2678C5.25286 39.0699 4.687 38.9705 4.15019 38.9705C3.54427 38.9705 2.98842 39.0997 2.48365 39.3573C1.97889 39.6148 1.5943 39.9967 1.3299 40.5038C1.0645 41.0099 0.932296 41.6036 0.932296 42.2847C0.932296 42.9659 1.05949 43.5108 1.31388 43.9821C1.56826 44.4544 1.95385 44.8174 2.47063 45.071C2.98742 45.3255 3.54727 45.4518 4.15019 45.4518C4.7531 45.4518 5.30594 45.3424 5.82173 45.1217L5.84877 45.8515C5.52528 45.9599 5.23584 46.0355 4.97744 46.0783C4.72005 46.122 4.43061 46.1429 4.11213 46.1429C3.53125 46.1429 2.99743 46.0594 2.5117 45.8913C2.02496 45.7243 1.61834 45.4836 1.29184 45.1724C0.965346 44.8601 0.71096 44.5071 0.529684 44.1104C0.264281 43.5446 0.13208 42.9211 0.13208 42.24C0.13208 41.707 0.215206 41.2078 0.379456 40.7424C0.544707 40.2771 0.768047 39.8873 1.04947 39.5721C1.3309 39.2569 1.62936 39.0142 1.94483 38.8452C2.26031 38.6761 2.61385 38.5449 3.00344 38.4534C3.39404 38.3609 3.80666 38.3152 4.24233 38.3152C4.52576 38.3152 4.8192 38.344 5.12467 38.4017C5.43013 38.4633 5.68953 38.5339 5.90385 38.6125L5.90285 38.6135Z"
				fill={themeColor}
			/>
			<path
				d="M14.9385 46.0732V38.3857H15.6846V45.408H18.8214V46.0732H14.9395H14.9385Z"
				fill={themeColor}
			/>
			<path
				d="M30.3701 38.3857H31.1163V46.0732H30.3701V38.3857Z"
				fill={themeColor}
			/>
			<path
				d="M41.8045 46.0732V38.3857H42.7459L46.6939 45.1485V38.3857H47.407V46.0732H46.4756L42.5005 39.3055V46.0732H41.8035H41.8045Z"
				fill={themeColor}
			/>
			<path
				d="M58.0962 38.3857H58.8423V46.0732H58.0962V38.3857Z"
				fill={themeColor}
			/>
			<path
				d="M75.2181 38.6135L75.164 39.2678C74.5681 39.0699 74.0022 38.9705 73.4654 38.9705C72.8595 38.9705 72.3036 39.0997 71.7989 39.3573C71.2941 39.6148 70.9095 39.9967 70.6451 40.5038C70.3797 41.0099 70.2475 41.6036 70.2475 42.2847C70.2475 42.9659 70.3747 43.5108 70.6281 43.9821C70.8825 44.4544 71.2681 44.8174 71.7848 45.071C72.3016 45.3255 72.8615 45.4518 73.4644 45.4518C74.0673 45.4518 74.6202 45.3424 75.1359 45.1217L75.163 45.8515C74.8395 45.9599 74.55 46.0355 74.2917 46.0783C74.0343 46.122 73.7448 46.1429 73.4263 46.1429C72.8455 46.1429 72.3116 46.0594 71.8259 45.8913C71.3392 45.7243 70.9325 45.4836 70.6061 45.1724C70.2796 44.8601 70.0252 44.5071 69.8439 44.1104C69.5785 43.5446 69.4463 42.9211 69.4463 42.24C69.4463 41.707 69.5294 41.2078 69.6937 40.7424C69.8589 40.2771 70.0823 39.8873 70.3637 39.5721C70.6451 39.2569 70.9436 39.0142 71.259 38.8452C71.5745 38.6761 71.9281 38.5449 72.3177 38.4534C72.7083 38.3609 73.1209 38.3152 73.5565 38.3152C73.84 38.3152 74.1334 38.344 74.4389 38.4017C74.7443 38.4633 75.0037 38.5339 75.2181 38.6125V38.6135Z"
				fill={themeColor}
			/>
			<path
				d="M89.7692 46.0732H88.969L88.1307 44.0079H84.2108L83.3665 46.0732H82.6204L85.8052 38.3857H86.6875L89.7692 46.0732ZM84.4932 43.3437H87.8743L86.1978 39.0778L84.4942 43.3437H84.4932Z"
				fill={themeColor}
			/>
			<path
				d="M98.1169 46.0732V38.3857H98.8631V45.408H102V46.0732H98.1179H98.1169Z"
				fill={themeColor}
			/>
		</svg>
	);
};

export default KYClinical;
