import React from 'react';
import Grid, { GridProps, GridSize } from '@mui/material/Grid';

export type ColProps = Omit<GridProps, 'item'>;
export type { GridSize };

const Col: React.ForwardRefRenderFunction<HTMLDivElement, ColProps> = (
	props,
	ref,
) => (
	<Grid
		item
		flexDirection={props.direction || 'column'}
		{...props}
		ref={ref}
	/>
);

export default React.forwardRef(Col);
