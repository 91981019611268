import React, { useCallback } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel, {
	FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import FormLabel, { FormLabelProps } from '@mui/material/FormLabel';
import clsx from 'clsx';
import { Optional } from '@interfaces';

import Checked from '@ui/icons/Checked';
import UnChecked from '@ui/icons/UnChecked';
import { styled } from '@ui/styles';

import { DropDownOption } from '../DropDown/types';

export type CheckBoxGroupProps = {
	options: DropDownOption[];
	name: string;
	id?: string;
	value: string;
	disabled?: boolean;
	isRequired?: boolean;
	error?: boolean;
	noWrap?: boolean;
	fullWidth?: boolean;
	disableOtherOnValue?: string;
	helperText?: string | number | React.ReactElement;
	label?: string | number | React.ReactElement;
	row?: boolean;
	onChange: (e: string[]) => void;
	onBlur?: () => void;
} & Omit<Optional<FormControlLabelProps, 'label' | 'control'>, 'onChange'>;

const Label = styled(props => (
	<FormLabel {...props} component="legend" />
))<FormLabelProps>(({ theme }) => ({
	marginLeft: 0,
}));
const CheckBoxGroup: React.FC<React.PropsWithChildren<CheckBoxGroupProps>> = ({
	id,
	name,
	value,
	disabled,
	label,
	fullWidth,
	disableOtherOnValue,
	options,
	error,
	onChange,
	className,
	onBlur,
	helperText,
	children,
	row,
	isRequired,
	...rest
}) => {
	const handleChange = useCallback(
		(event: React.SyntheticEvent, slected: boolean) => {
			const evt = event as React.ChangeEvent<HTMLInputElement>;
			if (disabled) {
				return;
			}
			if (!Array.isArray(value)) {
				console.error(
					`${name} is not an array. Did you initialize this field as an array?`,
				); //eslint-disable-line
			}
			let valueArray = [...value];
			if (slected || evt.currentTarget.checked) {
				valueArray.push(evt.currentTarget.value);
			} else {
				valueArray = valueArray.filter(
					m => m !== evt.currentTarget.value,
				);
			}
			onChange(valueArray);
			if (onBlur) {
				onBlur();
			}
		},
		[disabled, value, name, onChange, onBlur],
	);

	return (
		<Box sx={{ display: 'flex' }} ml={0.3}>
			<FormControl
				fullWidth={fullWidth}
				error={error}
				component="fieldset"
				variant="standard"
			>
				<Label>
					{label || children}
					{isRequired ? '*' : ''}
				</Label>
				<FormGroup row={row}>
					{options.map((m: DropDownOption, idx: number) => (
						<FormControlLabel
							key={`${id}-${idx}`}
							{...rest}
							className={clsx('full-width', className)}
							{...{
								id: `${id}-${m.value}-${idx}`,
								label: m.label,
								value: m.value,
								checked: value.includes(`${m.value}`),
								disabled: Boolean(
									disabled ||
										(disableOtherOnValue &&
											`${m.value}` !==
												disableOtherOnValue &&
											value.includes(
												disableOtherOnValue,
											)) ||
										(`${m.value}` === disableOtherOnValue &&
											!value.includes(`${m.value}`) &&
											value.length),
								),
							}}
							control={
								<Checkbox
									checkedIcon={<Checked />}
									icon={<UnChecked />}
								/>
							}
							onChange={handleChange}
							onBlur={onBlur}
						/>
					))}
				</FormGroup>
				<FormHelperText>{helperText}</FormHelperText>
			</FormControl>
		</Box>
	);
};

export default CheckBoxGroup;
