/* eslint-disable react/no-children-prop */
import React from 'react';
import CardActions from '@mui/material/CardActions';

import { CardActionsProps as IProps } from './card-actions';

export type CardActionsProps = IProps;
const TheCardActions: React.ForwardRefRenderFunction<
	HTMLDivElement,
	CardActionsProps
> = (props, ref) => {
	return (
		<CardActions
			{...{
				...props,
				ref,
			}}
		/>
	);
};

export default React.memo(React.forwardRef(TheCardActions));
