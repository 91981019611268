import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const Camera: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#00BCC1';
	return (
		<svg
			width="36"
			height="22"
			viewBox="0 0 36 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<rect
				x="1"
				y="3.04248"
				width="19.974"
				height="15.9154"
				rx="3"
				stroke={themeColor}
				strokeWidth="2"
			/>
			<path
				d="M30.6556 17.9223L21.514 11.0002L30.6556 4.07805V17.9223Z"
				stroke={themeColor}
				strokeWidth="2"
			/>
		</svg>
	);
};

export default Camera;
