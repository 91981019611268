import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const Calendar: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#F29FC5';
	return (
		<svg
			width="21"
			height="20"
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M8.21323 2.98269C8.72829 2.98269 9.24324 2.57492 9.37196 2.06002L9.67238 0.922673C9.80111 0.407612 9.50069 0 9.00718 0C8.49212 0 7.97717 0.407765 7.84845 0.922673L7.54788 2.06002C7.41914 2.55354 7.71957 2.98269 8.21323 2.98269Z"
				fill={themeColor}
			/>
			<path
				d="M14.565 2.98269C15.0801 2.98269 15.595 2.57492 15.7238 2.06002L16.0242 0.922673C16.1529 0.407612 15.8525 0 15.359 0C14.8439 0 14.329 0.407765 14.2003 0.922673L13.8784 2.06002C13.7495 2.55354 14.05 2.98269 14.565 2.98269Z"
				fill={themeColor}
			/>
			<path
				d="M17.6337 1.30863H16.7324L16.4749 2.25288C16.2603 3.11117 15.4234 3.75499 14.565 3.75499C14.0929 3.75499 13.6424 3.56189 13.3847 3.19709C13.1057 2.8323 13.02 2.33879 13.1487 1.86665L13.299 1.30874L10.4018 1.30859L10.1443 2.25284C9.90825 3.11114 9.09277 3.75495 8.21305 3.75495C7.74095 3.75495 7.31176 3.56186 7.03275 3.19705C6.75374 2.83225 6.66796 2.33876 6.79669 1.86661L6.94698 1.30871H6.00273C4.56496 1.30871 3.12722 2.42463 2.76242 3.8624L0.10127 13.6693C-0.113385 14.4418 0.0154976 15.2144 0.466059 15.7723C0.895207 16.3302 1.56044 16.6521 2.31156 16.6521H3.62058V17.2316C3.62058 18.7553 4.86522 19.9998 6.38875 19.9998H17.6334C19.1571 19.9998 20.4016 18.7551 20.4016 17.2316L20.4017 4.07704C20.4017 2.55335 19.1571 1.30871 17.6336 1.30871L17.6337 1.30863ZM2.3332 14.9782C2.1615 14.9782 1.94701 14.9352 1.81814 14.7636C1.6894 14.6133 1.66785 14.3774 1.75377 14.1197L3.94266 5.92228H17.2903L15.2088 13.6905C15.0157 14.3987 14.2645 14.9996 13.5779 14.9996L2.33325 14.9997L2.3332 14.9782ZM18.7281 17.2313C18.7281 17.8322 18.2346 18.3257 17.6337 18.3257L6.4105 18.3259C5.80964 18.3259 5.31612 17.8323 5.31612 17.2315V16.652H13.578C15.0157 16.652 16.4535 15.5361 16.8183 14.0983L18.7281 6.9953L18.7281 17.2313Z"
				fill={themeColor}
			/>
			<path
				d="M10.2515 10.9653H8.25582C8.12708 10.9653 7.9768 11.0727 7.93399 11.2014L7.5478 12.6605C7.50484 12.7893 7.59076 12.8966 7.71949 12.8966H9.71513C9.84386 12.8966 9.99415 12.7893 10.037 12.6605L10.4231 11.2014C10.466 11.0727 10.3802 10.9653 10.2515 10.9653Z"
				fill={themeColor}
			/>
			<path
				d="M6.73241 10.9653H4.73677C4.60804 10.9653 4.45776 11.0727 4.41495 11.2014L4.02875 12.6605C3.98579 12.7893 4.07172 12.8966 4.20045 12.8966H6.19609C6.32482 12.8966 6.4751 12.7893 6.51791 12.6605L6.90411 11.2014C6.94692 11.0727 6.88255 10.9653 6.73241 10.9653Z"
				fill={themeColor}
			/>
			<path
				d="M13.7708 10.9653H11.7752C11.6464 10.9653 11.4961 11.0727 11.4533 11.2014L11.0668 12.6607C11.0239 12.7894 11.1098 12.8968 11.2385 12.8968H13.2342C13.3629 12.8968 13.5132 12.7894 13.556 12.6607L13.9422 11.2015C13.9853 11.0727 13.8995 10.9653 13.7708 10.9653Z"
				fill={themeColor}
			/>
			<path
				d="M11.0674 7.89648H9.07173C8.943 7.89648 8.79272 8.00381 8.74991 8.13254L8.36372 9.5917C8.32075 9.72043 8.40668 9.82776 8.53541 9.82776H10.531C10.6598 9.82776 10.8101 9.72043 10.8529 9.5917L11.2391 8.13254C11.282 8.00381 11.2177 7.89648 11.0674 7.89648Z"
				fill={themeColor}
			/>
			<path
				d="M7.56933 7.89648H5.57369C5.44496 7.89648 5.29467 8.00381 5.25186 8.13254L4.86567 9.5917C4.82271 9.72043 4.90863 9.82776 5.03736 9.82776H7.033C7.16173 9.82776 7.31202 9.72043 7.35483 9.5917L7.74102 8.13254C7.78383 8.00381 7.69806 7.89648 7.56933 7.89648Z"
				fill={themeColor}
			/>
			<path
				d="M12.0757 9.84924H14.0715C14.2003 9.84924 14.3505 9.74192 14.3933 9.61319L14.7795 8.15403C14.8225 8.0253 14.7366 7.91797 14.6078 7.91797H12.6122C12.4835 7.91797 12.3332 8.0253 12.2904 8.15403L11.9042 9.61319C11.8612 9.72051 11.9256 9.84924 12.0757 9.84924Z"
				fill={themeColor}
			/>
		</svg>
	);
};

export default Calendar;
