import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers/TimePicker';
import React from 'react';

export type TimeInputProps = React.PropsWithChildren<
	TimePickerProps<never> & {
		variant?: any;
		error?: boolean;
		fullWidth?: boolean;
		isRequired?: boolean;
		helperText?: string;
	}
>;

const TimeInput: React.FC<TimeInputProps> = ({
	error,
	label,
	fullWidth,
	children,
	isRequired,
	...rest
}) => {
	return (
		<FormControl error={error} fullWidth={fullWidth}>
			<FormLabel className="input-label">
				{label || children}
				{isRequired ? '*' : ''}
			</FormLabel>
			<TimePicker
				{...rest}
				// renderInput={params => (
				// 	<TextField
				// 		{...params}
				// 		error={error}
				// 		fullWidth={fullWidth}
				// 		helperText={helperText}
				// 	/>
				// )}
			/>
		</FormControl>
	);
};
export default TimeInput;
