import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const Yahoo: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#673AB7';
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 48 48"
			width="48px"
			height="48px"
			{...rest}
		>
			<path
				fill={themeColor}
				d="M9,5c0,0,1.4,0.3,2.5,0.3S14,5,14,5l10,17L34.1,5c0,0,1.1,0.4,2.4,0.4C37.9,5.4,39,5,39,5L26,27l0.4,16c0,0-1.4-0.4-2.4-0.4S21.5,43,21.5,43L22,27L9,5z"
			/>
		</svg>
	);
};

export default Yahoo;
