import assert from 'assert';

import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MuiSlider from '@mui/material/Slider';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';

import { styled } from '@ui/styles';
import Text from '@ui/components/Text';
import Box from '@ui/components/Box';

import numberParse from '@common-lib/extractors/numberParse';
import percentageFormat from '@common-lib/format/percentageFormat';

import SliderProps from './Slider';

export type { SliderProps };
const InputBox = styled(Box)(() => ({
	maxWidth: 80,
}));
const SliderBox = styled(Box)(({ theme }) => ({
	width: '100%',
	paddingLeft: theme.spacing(3),
	paddingRight: theme.spacing(3),
}));
const Slider: React.FC<SliderProps & { component?: React.ElementType }> = ({
	color = 'secondary',
	value = 0,
	label,
	labelLeft,
	labelRight,
	helperText,
	disabled,
	error,
	component = Box,
	onChange,
	...rest
}) => {
	const handleLeftChange = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			let val = numberParse(e.target.value);
			if (!val) {
				return;
			}
			if (val > 100 || val < -100) {
				val = 100;
			}
			onChange(Math.abs(val));
		},
		[],
	);
	const handleRightChange = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			let val = numberParse(e.target.value);
			if (!val) {
				return;
			}
			if (val > 100 || val < -100) {
				val = 100;
			}
			onChange(100 - Math.abs(val));
		},
		[],
	);
	const handleSliderChange = React.useCallback(
		(e: Event, value: number | number[]) => {
			onChange(Array.isArray(value) ? value[0] : value);
		},
		[],
	);
	assert(value >= 0 && value <= 100, 'Value has to be between 0-100');
	const leftValue = value;
	const rightValue = 100 - value;
	return (
		<FormControl error={error} fullWidth>
			<FormLabel className="text-label">{label}</FormLabel>
			<Box
				pt={1}
				width="100%"
				maxWidth={800}
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				px={1}
			>
				<Text color="black">{labelLeft}</Text>
				<Text color="black" textAlign="right">
					{labelRight}
				</Text>
			</Box>
			<Box
				width="100%"
				maxWidth={800}
				display="flex"
				flexDirection="row"
				alignItems="center"
			>
				<InputBox>
					<TextField
						value={percentageFormat(leftValue)}
						onChange={handleLeftChange}
						error={error}
					/>
				</InputBox>
				<SliderBox>
					<MuiSlider
						{...rest}
						component={component}
						color={color}
						value={value}
						disabled={disabled}
						error={error}
						onChange={handleSliderChange}
					/>
				</SliderBox>
				<InputBox>
					<TextField
						value={percentageFormat(rightValue)}
						onChange={handleRightChange}
						error={error}
					/>
				</InputBox>
			</Box>
			{helperText ? (
				<FormHelperText error={error}>{helperText}</FormHelperText>
			) : null}
		</FormControl>
	);
};

export default Slider;
