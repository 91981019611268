import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const VideoChat: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#600160';
	return (
		<svg
			width="297"
			height="297"
			viewBox="0 0 297 297"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M287.067,61.511h-92.845c0.386-2.41,0.594-4.879,0.594-7.397c0-25.61-20.77-46.445-46.297-46.445
		c-25.528,0-46.296,20.835-46.296,46.445c0,2.519,0.207,4.987,0.594,7.397H9.933C4.447,61.511,0,65.958,0,71.443v207.956
		c0,5.485,4.447,9.933,9.933,9.933h277.135c5.485,0,9.933-4.447,9.933-9.933V71.443C297,65.958,292.553,61.511,287.067,61.511z
		 M148.519,22.567c17.313,0,31.398,14.151,31.398,31.546c0,17.395-14.085,31.547-31.398,31.547
		c-17.312,0-31.396-14.151-31.396-31.547C117.123,36.719,131.207,22.567,148.519,22.567z M111.072,81.377
		c8.423,11.613,22.067,19.183,37.447,19.183s29.024-7.569,37.448-19.183h91.166v153.431h-73.496
		c-1.508-11.762-6.2-21.923-13.851-29.754c-6.874-7.034-15.886-11.907-26.174-14.296c14.822-5.996,25.314-20.535,25.314-37.489
		c0-22.289-18.127-40.423-40.407-40.423c-22.279,0-40.405,18.134-40.405,40.423c0,16.953,10.49,31.493,25.311,37.489
		c-10.287,2.387-19.299,7.261-26.171,14.295c-7.651,7.831-12.345,17.992-13.852,29.755H19.866V81.377H111.072z M148.519,173.829
		c-11.325,0-20.539-9.223-20.539-20.561c0-11.335,9.214-20.557,20.539-20.557c11.326,0,20.541,9.222,20.541,20.557
		C169.06,164.606,159.846,173.829,148.519,173.829z M175.577,218.936c4.102,4.198,6.771,9.515,7.984,15.871h-70.082
		c1.213-6.357,3.883-11.674,7.984-15.872c6.3-6.447,15.908-9.999,27.055-9.999C159.667,208.936,169.275,212.488,175.577,218.936z
		 M19.866,269.466v-14.793h82.942h91.424h82.901v14.793H19.866z"
				fill={themeColor}
			/>
			<path
				d="M148.519,76.614c12.359,0,22.416-10.09,22.416-22.491c0-12.399-10.057-22.488-22.416-22.488
		c-12.358,0-22.412,10.089-22.412,22.488C126.107,66.524,136.161,76.614,148.519,76.614z M147.53,37.646
		c0.617,1.435,0.97,3.012,0.97,4.675c0,6.525-5.277,11.813-11.772,11.813c-1.64,0-3.196-0.345-4.614-0.952
		C132.586,44.838,139.231,38.145,147.53,37.646z"
				fill={themeColor}
			/>
		</svg>
	);
};

export default VideoChat;
