import React from 'react';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps as MuiSwitchProps } from '@mui/material/Switch';
import FormControlLabel, {
	FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

const Android12Switch = styled(Switch)(({ theme }) => ({
	padding: 8,
	marginLeft: 4,
	'& .MuiSwitch-track': {
		borderRadius: 22 / 2,
		'&:before, &:after': {
			content: '""',
			position: 'absolute',
			top: '50%',
			transform: 'translateY(-50%)',
			width: 16,
			height: 16,
		},
		'&:before': {
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
				theme.palette.getContrastText(theme.palette.primary.main),
			)}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
			left: 4,
		},
		'&:after': {
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
				theme.palette.getContrastText(theme.palette.primary.main),
			)}" d="M19,13H5V11H19V13Z" /></svg>')`,
			right: 4,
		},
	},
	'& .MuiSwitch-thumb': {
		boxShadow: 'none',
		width: 16,
		height: 16,
		margin: 2,
	},
}));

type SwitchProps = Omit<MuiSwitchProps, 'onChange'> &
	Omit<FormControlLabelProps, 'control' | 'label'> & {
		noWrap?: boolean;
		error?: boolean;
		fullWidth?: boolean;
		helperText?: string | number | React.ReactElement;
		label?: string | number | React.ReactElement;
	};

const SwitchInput: React.FC<React.PropsWithChildren<SwitchProps>> = ({
	children,
	label,
	id,
	disabled,
	error,
	fullWidth,
	name,
	color,
	size,
	helperText,
	disableTypography,
	sx,
	...rest
}) => {
	return (
		<FormControl error={error} sx={sx} fullWidth={fullWidth}>
			<FormControlLabel
				{...{
					id: id || name,
					disabled,
					name,
					disableTypography,
					...rest,
				}}
				label={label || (children as any)}
				control={<Android12Switch />}
			/>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
};

export type { SwitchProps };

export default SwitchInput;
