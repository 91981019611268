import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const CheckCircle: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || palette.success.main;
	return (
		<svg
			version="1.2"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 52 52"
			width="52"
			height="52"
			{...rest}
		>
			<path
				fill={themeColor}
				d="m26 1.6c13.4 0 24.3 10.8 24.3 24.2 0 13.4-10.9 24.3-24.3 24.3-13.4 0-24.3-10.9-24.3-24.3 0-13.4 10.9-24.2 24.3-24.2z"
			/>
			<path
				fill="white"
				d="m23.9 40c-0.9 0-1.8-0.4-2.4-1.1l-8.8-10.2c-1.2-1.3-1-3.3 0.3-4.4 1.3-1.1 3.2-1 4.3 0.3l6.4 7.4 12.7-16.8c1.1-1.3 3-1.6 4.4-0.6 1.4 1.1 1.6 3 0.6 4.4l-15 19.8c-0.6 0.7-1.5 1.2-2.4 1.2q-0.1 0-0.1 0z"
			/>
		</svg>
	);
};

export default CheckCircle;
