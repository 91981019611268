import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { TooltipProps as IProps } from './tooltip';

export type TooltipProps = IProps;

const TheTooltip: React.ForwardRefRenderFunction<
	HTMLDivElement,
	TooltipProps
> = (props, ref) => {
	const {
		arrow,
		controlled,
		children,
		disableInteractive,
		disableHoverListener,
		disableFocusListener,
		disableTouchListener,
		isIcon = false,
		iconButtonProps,
		boxProps,
		...rest
	} = props;
	const [open, setOpen] = React.useState(false);
	const handleClose = React.useCallback(() => {
		setOpen(false);
	}, []);
	const handleOpen = React.useCallback(() => {
		setOpen(true);
	}, []);
	const controlledProps = controlled
		? {
				open,
				onClose: handleClose,
				onOpen: handleOpen,
				disableInteractive,
				disableHoverListener,
				disableFocusListener,
				disableTouchListener,
				PopperProps: {
					disablePortal: true,
				},
		  }
		: {
				disableInteractive,
				disableHoverListener,
				disableFocusListener,
				disableTouchListener,
		  };
	const button = isIcon ? (
		<IconButton onClick={handleOpen} {...(iconButtonProps || {})}>
			{children}
		</IconButton>
	) : (
		<Box {...(boxProps || {})}>{children}</Box>
	);
	return (
		<>
			<ClickAwayListener onClickAway={handleClose}>
				<Tooltip
					{...{
						...rest,
						...controlledProps,
						ref,
						arrow: typeof arrow !== 'undefined' ? arrow : true,
					}}
				>
					{button}
				</Tooltip>
			</ClickAwayListener>
		</>
	);
};

export default React.memo(React.forwardRef(TheTooltip));
