import React from 'react';

import Link from '@ui/components/Link';
import Text from '@ui/components/Text';
import Image from '@ui/components/Image';
import Box, { BoxProps } from '@ui/components/Box';
import { styled } from '@ui/styles';
import { Row, Col } from '@ui/components';
import BrandCodeContext from '@web/components/BrandCodeContext';
import clientConfig from '@common-lib/clientConfig';

const Wrapper = styled(Box)(({ theme }) => ({
	paddingBottom: theme.spacing(2),
}));
const LinkCol = styled(Col)(({ theme }) => ({
	flex: '1 1 auto !important',
}));

const LinkText = styled(Text)(({ theme }) => ({
	[theme.breakpoints.down('sm')]: {
		fontSize: '0.625rem',
		lineHeight: '0.8125rem',
	},
	cursor: 'pointer',
}));
const LinkTextDisabled = styled(Text)(({ theme }) => ({
	[theme.breakpoints.down('sm')]: {
		fontSize: '0.625rem',
		lineHeight: '0.8125rem',
	},
}));
const A = styled('a')(({ theme }) => ({
	cursor: 'pointer',
}));

const Border = styled(Box)(({ theme }) => ({
	height: 1,
	backgroundImage: 'linear-gradient(270deg, #EC008C -10.42%, #F29FC5 100%)',
	marginBottom: theme.spacing(2.5),
}));

const TText = styled(Text)(({ theme }) => ({
	borderRight: '1px solid black',
	paddingRight: theme.spacing(1),
	[theme.breakpoints.down('sm')]: {
		fontSize: '0.625rem',
		lineHeight: '0.8125rem',
	},
	cursor: 'pointer',
}));

const { host } = clientConfig;

const BottomFooter: React.FC<Omit<BoxProps, 'ref'> & { noTerms?: boolean }> = ({
	noTerms,
	...props
}) => {
	const bCode = React.useContext(BrandCodeContext);
	const brandCode = (bCode as any) === 'semina' ? undefined : bCode;
	return (
		<>
			<Border />
			<Wrapper {...props}>
				<Row alignItems="center">
					{brandCode ? (
						<Col xs={12} md="auto">
							<Box pr={1.5}>
								{noTerms ? (
									<Image
										src="/logo_semina.png"
										alt="logo"
										height={24}
										width={130}
										priority
									/>
								) : (
									<Link
										href={`${host}/`}
										passHref
										legacyBehavior
									>
										<A>
											<Image
												src="/logo_semina.png"
												alt="logo"
												height={24}
												width={130}
												priority
											/>
										</A>
									</Link>
								)}
							</Box>
						</Col>
					) : null}
					<LinkCol xs={12} md="auto">
						<Row justifyContent="space-between" mt={-0.3}>
							<Col>
								<LinkTextDisabled component="a">
									Todos os direitos reservados
								</LinkTextDisabled>
							</Col>
							<Col>
								{noTerms ? (
									<Box />
								) : (
									<Row flexWrap="nowrap" spacing={1}>
										<Col>
											<Link
												href={`${host}/termos`}
												passHref
												legacyBehavior
											>
												<TText component="a">
													Termos
												</TText>
											</Link>
										</Col>
										<Col>
											<Link
												href={`${host}/privacidade`}
												passHref
												legacyBehavior
											>
												<LinkText component="a">
													Política de Privacidade
												</LinkText>
											</Link>
										</Col>
									</Row>
								)}
							</Col>
						</Row>
					</LinkCol>
				</Row>
			</Wrapper>
		</>
	);
};

export default BottomFooter;
