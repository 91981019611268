import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const Search: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#634B99';
	const bgColor =
		(color && palette[color]?.lighter) || htmlColor || '#F8F8F8';
	return (
		<svg
			width="42"
			height="42"
			viewBox="0 0 42 42"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<circle cx="21" cy="21" r="21" fill={bgColor} />
			<path
				d="M27.9005 26.359L24.9958 23.4544C26.079 22.24 26.7518 20.6318 26.7518 18.8759C26.7518 15.0851 23.6667 12 19.8759 12C16.0851 12 13 15.0851 13 18.8759C13 22.6667 16.0851 25.7518 19.8759 25.7518C21.2216 25.7518 22.4687 25.3579 23.519 24.7015L26.5385 27.721C26.719 27.9016 26.9651 28 27.2113 28C27.4574 28 27.7037 27.9016 27.8842 27.721C28.278 27.3436 28.278 26.7364 27.9005 26.359L27.9005 26.359ZM14.92 18.8759C14.92 16.1518 17.1354 13.92 19.8759 13.92C22.6163 13.92 24.8154 16.1518 24.8154 18.8759C24.8154 21.6001 22.5999 23.8318 19.8759 23.8318C17.1518 23.8318 14.92 21.6164 14.92 18.8759Z"
				fill={themeColor}
			/>
		</svg>
	);
};

export default Search;
