import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import React from 'react';

export type TextAreaProps = Omit<TextFieldProps, 'variant' | 'multiline'> & {
	variant?: any;
	isRequired?: boolean;
};

const TextInput: React.ForwardRefRenderFunction<
	HTMLDivElement,
	TextAreaProps
> = ({ error, label, fullWidth, children, isRequired, ...rest }, ref) => {
	return (
		<FormControl error={error} fullWidth={fullWidth}>
			<FormLabel className="text-label multi">
				{label || children}
				{isRequired ? '*' : ''}
			</FormLabel>
			<TextField
				ref={ref}
				multiline
				error={error}
				fullWidth={fullWidth}
				{...rest}
			/>
		</FormControl>
	);
};

export default React.memo(React.forwardRef(TextInput));
