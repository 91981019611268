/* eslint-disable react/no-children-prop */
import { Box } from '@ui/components';
import { styled } from '@ui/styles';

const ContentBox = styled(Box)(({ theme, maxWidth }) => ({
	maxWidth: maxWidth ? undefined : 1212,
	width: '100%',
	position: 'relative',
}));

export default ContentBox;
