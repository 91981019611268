import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const Office365: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#e64a19';
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 48 48"
			width="48px"
			height="48px"
			{...rest}
		>
			<path
				fill={themeColor}
				d="M7 12L29 4 41 7 41 41 29 44 7 36 29 39 29 10 15 13 15 33 7 36z"
			/>
		</svg>
	);
};

export default Office365;
