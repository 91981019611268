import React from 'react';
import ButtonGroup, {
	ButtonGroupProps as Props,
} from '@mui/material/ButtonGroup';

export type ButtonGroupProps = Props;

const TheButtonGroup: React.ForwardRefRenderFunction<
	HTMLDivElement,
	ButtonGroupProps
> = ({ variant, size, ...props }, ref) => {
	return (
		<ButtonGroup
			{...{
				...props,
				variant: variant || 'contained',
				size: size || 'large',
				ref,
			}}
		/>
	);
};

export default React.forwardRef(TheButtonGroup);
