/* eslint-disable react/no-children-prop */
import { Box } from '@ui/components';
import { styled } from '@ui/styles';

const ContentWrapper = styled(Box)(({ theme }) => ({
	[theme.breakpoints.up('sm')]: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	position: 'relative',
}));

export default ContentWrapper;
