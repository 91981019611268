import React, { useCallback } from 'react';
import Radio from '@mui/material/Radio';
import FormControlLabel, {
	FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import { Optional } from '@interfaces';

import RadioChecked from '@ui/icons/RadioChecked';
import RadioUnChecked from '@ui/icons/RadioUnChecked';

import { DropDownOption } from '../DropDown/types';

export type RadioGroupProps = {
	options: DropDownOption[];
	name: string;
	id?: string;
	value: string;
	disabled?: boolean;
	error?: boolean;
	noWrap?: boolean;
	isRequired?: boolean;
	fullWidth?: boolean;
	disableOtherOnValue?: string;
	helperText?: string | number | React.ReactElement;
	label?: string | number | React.ReactElement;
	row?: boolean;
	onChange: (e: string) => void;
} & Optional<FormControlLabelProps, 'label' | 'control'>;

const RadioGroup: React.FC<React.PropsWithChildren<RadioGroupProps>> = ({
	id,
	name,
	value,
	disabled,
	label,
	fullWidth,
	disableOtherOnValue,
	options,
	error,
	onChange,
	className,
	onBlur,
	helperText,
	row = false,
	children,
	isRequired,
	...rest
}) => {
	const handleChange = useCallback(
		(event: React.SyntheticEvent) => {
			const evt = event as React.ChangeEvent<HTMLInputElement>;
			if (disabled) {
				return;
			}
			onChange(evt.currentTarget.value);
			if (onBlur) {
				// @ts-ignore this should work
				onBlur();
			}
		},
		[disabled, value, name, onChange, onBlur],
	);

	return (
		<Box sx={{ display: 'flex' }}>
			<FormControl
				fullWidth={fullWidth}
				error={error}
				component="fieldset"
				variant="standard"
			>
				<FormLabel component="label" className="no-margin">
					{label || children}
					{isRequired ? '*' : ''}
				</FormLabel>
				<FormGroup row={row}>
					{options.map((m: DropDownOption, idx: number) => (
						<FormControlLabel
							key={`${id || name}-${idx}`}
							{...rest}
							className={clsx('full-width', className)}
							{...{
								id: `${id || name}-${m.value}-${idx}`,
								label: m.label,
								value: m.value,
								checked: value === m.value,
								disabled: Boolean(
									disabled ||
										(disableOtherOnValue &&
											`${m.value}` !==
												disableOtherOnValue &&
											value.includes(
												disableOtherOnValue,
											)) ||
										(`${m.value}` === disableOtherOnValue &&
											!value.includes(`${m.value}`) &&
											value.length),
								),
							}}
							control={
								<Radio
									checkedIcon={<RadioChecked />}
									icon={<RadioUnChecked />}
								/>
							}
							onChange={handleChange}
							onBlur={onBlur}
						/>
					))}
				</FormGroup>
				<FormHelperText>{helperText}</FormHelperText>
			</FormControl>
		</Box>
	);
};

export default RadioGroup;
