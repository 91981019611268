import React from 'react';
import { SxProps, Theme } from '@mui/material/styles';
import ContentWrapper from '@common-lib/components/Content/ContentWrapper';
import ContentBox from '@common-lib/components/Content/ContentBox';
import BrandBackground from '@common-lib/modules/brands/components/BrandBackground';
import BrandCodeContext from '@web/components/BrandCodeContext';

const ContentConditon: React.FC<
	React.PropsWithChildren<{
		contentColumn?: boolean;
		contentSx?: SxProps<Theme> | undefined;
	}>
> = ({ contentColumn, children, contentSx }) => {
	const brandCode = React.useContext(BrandCodeContext);
	if (contentColumn) {
		return (
			<ContentWrapper>
				<BrandBackground brandCode={brandCode} />
				<ContentBox sx={contentSx}>{children}</ContentBox>
			</ContentWrapper>
		);
	}
	return <>{children}</>;
};

export default ContentConditon;
