import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const Cartorio: React.FC<IconProps> = ({
	color,
	htmlColor,
	width,
	height,
	...rest
}) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || palette.primary.main;
	return (
		<svg
			width={width}
			height={height}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<symbol
				id={`cartorio_icon${themeColor}`}
				width="23"
				height="25"
				viewBox="0 0 23 25"
			>
				<path
					d="M11.3854 3.97559C11.3854 3.97559 3.53043 3.99902 3 3.99902C2.46957 3.99902 1.96086 4.20974 1.58579 4.58481C1.21071 4.95988 1 5.46859 1 5.99902C1 6.52945 1 21.999 1 21.999C1 22.5294 1.21071 23.0382 1.58579 23.4132C1.96086 23.7883 2.46957 23.999 3 23.999H15C15.5304 23.999 16.0391 23.7883 16.4142 23.4132C16.7893 23.0382 17 22.5294 17 21.999C17 21.999 17.0104 12.137 17 11.7594"
					stroke={themeColor}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M12.0554 14.999H5"
					stroke={themeColor}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M13 18.999H5"
					stroke={themeColor}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M7 10.999H6H5"
					stroke={themeColor}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.3822 12.2532C12.9422 12.3987 13.0597 12.4292 13.5633 12.9416C14.0669 13.4544 14.8513 13.5461 15.4562 13.1632C15.6982 13.0095 15.9789 12.9279 16.2656 12.9279C16.5522 12.9279 16.833 13.0095 17.075 13.1632C17.6802 13.5461 18.4646 13.4544 18.9682 12.9416C19.466 12.4347 19.5719 12.4067 20.1857 12.2443C20.2632 12.2238 20.3488 12.2011 20.4443 12.1751C20.973 12.0308 21.3863 11.6102 21.5279 11.072C21.6708 10.5297 21.7234 10.2923 21.8384 10.0905C21.9527 9.89009 22.1285 9.72478 22.5157 9.33063C22.9028 8.9363 23.0539 8.36194 22.9123 7.82373C22.7698 7.28244 22.6987 7.04974 22.6989 6.8171C22.699 6.58481 22.7702 6.35257 22.9123 5.81325C23.0543 5.27505 22.9028 4.70068 22.5157 4.30673C22.1285 3.9125 21.9525 3.74711 21.8382 3.54657C21.7231 3.34476 21.6705 3.10736 21.5279 2.56536C21.3863 2.02715 20.973 1.60656 20.4443 1.4623C19.9151 1.31791 19.6815 1.26422 19.4841 1.14872C19.2854 1.03251 19.1234 0.853732 18.7337 0.45706C18.3466 0.0627299 17.7822 -0.0910437 17.2535 0.0532142C16.7218 0.198136 16.4932 0.270455 16.2646 0.270315C16.0363 0.270174 15.8081 0.19776 15.278 0.0532142C14.7493 -0.0910437 14.1849 0.0631105 13.7978 0.45706C13.4076 0.854066 13.2454 1.03283 13.0466 1.14899C12.8492 1.26436 12.6156 1.31796 12.0868 1.4623C11.5582 1.60656 11.1448 2.02715 11.0032 2.56536C10.8604 3.10742 10.8078 3.34481 10.6928 3.54663C10.5785 3.74714 10.4026 3.91255 10.0155 4.30673C9.62837 4.70068 9.47726 5.27505 9.61885 5.81325C9.76126 6.35413 9.83234 6.58676 9.83224 6.81936C9.83214 7.0518 9.76097 7.2842 9.61885 7.82411C9.47726 8.36232 9.62837 8.93668 10.0155 9.33063C10.4026 9.72478 10.5785 9.89009 10.6927 10.0905C10.8078 10.2923 10.8604 10.5297 11.0032 11.072C11.1448 11.6102 11.5582 12.0308 12.0868 12.1751C12.198 12.2053 12.2954 12.2306 12.3822 12.2532ZM16.2657 10.7851C18.5219 10.7851 20.351 8.95607 20.351 6.69982C20.351 4.44356 18.5219 2.61451 16.2657 2.61451C14.0094 2.61451 12.1803 4.44356 12.1803 6.69982C12.1803 8.95607 14.0094 10.7851 16.2657 10.7851Z"
					fill={themeColor}
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M20.5644 11.4186L18.1802 12.3389L20.3077 17.8512L20.9803 16.0454L22.6921 16.9313L20.5644 11.4186Z"
					fill={themeColor}
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M13.4514 11.4186L15.8357 12.3389L13.7082 17.8511L13.0356 16.0454L11.3237 16.9313L13.4514 11.4186Z"
					fill={themeColor}
				/>
				<circle cx="16.29" cy="6.81641" r="2" fill={themeColor} />
			</symbol>
			<use
				href={`#cartorio_icon${themeColor}`}
				x="0"
				y="0"
				width={width}
				height={height}
			/>
		</svg>
	);
};

export default Cartorio;
