import React from 'react';

import useTheme from '@ui/styles/hooks/useTheme';

import { IconProps } from './props';

const Edit: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#634B99';
	return (
		<svg
			width="15"
			height="14"
			viewBox="0 0 15 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M10.4266 9.59214L11.2043 8.77964C11.3259 8.65269 11.5373 8.74155 11.5373 8.92437V12.6162C11.5373 13.289 11.0148 13.8349 10.3707 13.8349H1.8151C1.17101 13.8349 0.648438 13.289 0.648438 12.6162V3.67866C0.648438 3.00581 1.17101 2.45991 1.8151 2.45991H8.46267C8.63524 2.45991 8.72274 2.67827 8.60121 2.80776L7.82344 3.62026C7.78698 3.65835 7.73837 3.67866 7.6849 3.67866H1.8151V12.6162H10.3707V9.73433C10.3707 9.68101 10.3901 9.63023 10.4266 9.59214ZM14.2328 4.46831L7.85017 11.1359L5.65295 11.3898C5.01615 11.4634 4.47413 10.9023 4.54462 10.232L4.78767 7.93667L11.1703 1.26909C11.7269 0.687646 12.6262 0.687646 13.1804 1.26909L14.2304 2.36597C14.787 2.94741 14.787 3.8894 14.2328 4.46831ZM11.8314 5.25288L10.4193 3.77769L5.9033 8.4978L5.72587 10.1558L7.31302 9.97046L11.8314 5.25288ZM13.4064 3.22925L12.3564 2.13237C12.2568 2.02827 12.0939 2.02827 11.9967 2.13237L11.2457 2.91694L12.6578 4.39214L13.4089 3.60757C13.5061 3.50093 13.5061 3.33335 13.4064 3.22925Z"
				fill={themeColor}
			/>
		</svg>
	);
};

export default Edit;
