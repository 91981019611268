import React from 'react';

import './styles.css';
import { Instagram } from '@ui/icons';

import { InstagramItem, MediaType } from '@interfaces/instagram';

export type InstagramFeedProps = {
	data: InstagramItem[];
};

const InstagramFeed: React.FC<InstagramFeedProps> = ({ data }) => {
	return (
		<div>
			<div className="instagramItems">
				{data.map((item, index) => (
					<div key={index} className="instagramItem">
						<a
							key={index}
							href={item.permalink}
							className="ig-instagram-link"
							target="_blank"
							rel="noreferrer"
						>
							{item.media_type === MediaType.IMAGE ||
							item.media_type === MediaType.CAROUSEL_ALBUM ? (
								<img
									className="instagramImg"
									key={index}
									src={item.media_url}
									alt="description"
								/>
							) : (
								<video className="instagramImg" key={index}>
									<source
										src={item.media_url}
										// alt={item.caption}
										type="video/mp4"
									/>
								</video>
							)}

							<div className="instagramIcon">
								<div className="instagram-count-item">
									<span className="icon">
										<Instagram htmlColor="currentColor" />
									</span>
								</div>
							</div>
						</a>
					</div>
				))}
			</div>
		</div>
	);
};
export default InstagramFeed;
