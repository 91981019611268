import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const Warning: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#FFBA7B';
	return (
		<svg
			width="12"
			height="12"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M5.92531 12C7.90529 12 10.3654 11.556 11.3253 10.308C12.5673 8.72395 11.2052 5.61003 10.7612 4.69796C9.63927 2.35808 7.72519 0 5.92521 0C4.12522 0 2.21133 2.35795 1.07117 4.69796C0.627189 5.6099 -0.728815 8.724 0.507156 10.308C1.48509 11.5559 3.94512 11.9999 5.9251 11.9999L5.92531 12ZM5.32535 4.20016C5.32535 3.98581 5.43966 3.78773 5.62528 3.68055C5.8109 3.57338 6.03971 3.57338 6.22534 3.68055C6.41097 3.78773 6.52527 3.98581 6.52527 4.20016V6.6001C6.52527 6.81445 6.41096 7.01253 6.22534 7.1197C6.03972 7.22688 5.81091 7.22688 5.62528 7.1197C5.43965 7.01253 5.32535 6.81445 5.32535 6.6001V4.20016ZM5.92531 7.95004C6.12419 7.95004 6.31493 8.02909 6.45566 8.16971C6.59629 8.31044 6.67534 8.50119 6.67534 8.70006C6.67534 8.89894 6.59629 9.08968 6.45566 9.23042C6.31494 9.37104 6.12418 9.45009 5.92531 9.45009C5.72644 9.45009 5.53569 9.37104 5.39496 9.23042C5.25433 9.08969 5.17528 8.89894 5.17528 8.70006C5.17528 8.50119 5.25433 8.31044 5.39496 8.16971C5.53568 8.02909 5.72644 7.95004 5.92531 7.95004Z"
				fill="#FFBA7B"
			/>
		</svg>
	);
};

export default Warning;
