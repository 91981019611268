/* eslint-disable react/no-children-prop */
import React from 'react';
import Card from '@mui/material/Card';

import { CardProps as IProps } from './card';

export type CardProps = IProps;
const TheCard: React.ForwardRefRenderFunction<HTMLDivElement, CardProps> = (
	props,
	ref,
) => {
	return (
		<Card
			{...{
				...props,
				ref,
			}}
		/>
	);
};

export default React.memo(React.forwardRef(TheCard));
