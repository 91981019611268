/* eslint-disable react/no-children-prop */
import React from 'react';
import Chip from '@mui/material/Chip';

import { ChipProps as IProps } from './chip';

export type ChipProps = IProps;
const TheChip: React.ForwardRefRenderFunction<HTMLDivElement, ChipProps> = (
	props,
	ref,
) => {
	return (
		<Chip
			{...{
				...props,
				ref,
			}}
		/>
	);
};

export default React.memo(React.forwardRef(TheChip));
