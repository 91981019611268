import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const Back: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#9A4856';
	return (
		<svg
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<rect width="40" height="40" rx="8" fill={themeColor} />
			<path
				d="M26.5059 20.7711L26.5059 19.756L15.3628 19.756L20.271 15.7183L19.3987 15L12.9995 20.2638L19.3987 25.527L20.2714 24.809L15.3628 20.771L26.5059 20.7711Z"
				fill="white"
			/>
		</svg>
	);
};

export default Back;
