import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const BulletDimond: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#9A4856';
	return (
		<svg
			width="10"
			height="8"
			viewBox="0 0 10 8"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10 3.5166L5.00068 7.0166L0 3.5166L5.00068 0.0166016L10 3.5166Z"
				fill={themeColor}
			/>
		</svg>
	);
};

export default BulletDimond;
