import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const Calendar2: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#F29FC5';
	return (
		<svg
			width="15"
			height="17"
			viewBox="0 0 15 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M14.1539 1.68096H11.0769V1.06557C11.0769 0.845654 10.9596 0.642503 10.7692 0.532613C10.5788 0.422723 10.3442 0.422723 10.1539 0.532613C9.96347 0.642503 9.84616 0.845661 9.84616 1.06557V1.68096H4.92308V1.06557C4.92308 0.845654 4.80577 0.642503 4.61539 0.532613C4.425 0.422723 4.19038 0.422723 4 0.532613C3.80962 0.642503 3.69231 0.845661 3.69231 1.06557V1.68096H0.615385C0.452199 1.68096 0.295603 1.74579 0.18022 1.86118C0.064837 1.97656 0 2.13316 0 2.29634V15.8348C0 15.998 0.064837 16.1546 0.18022 16.27C0.295603 16.3854 0.452199 16.4502 0.615385 16.4502H14.1539C14.317 16.4502 14.4736 16.3854 14.589 16.27C14.7044 16.1546 14.7692 15.998 14.7692 15.8348V2.29634C14.7692 2.13316 14.7044 1.97656 14.589 1.86118C14.4736 1.74579 14.317 1.68096 14.1539 1.68096ZM1.23077 2.91173H3.69231V3.52711C3.69231 3.74703 3.80962 3.95018 4 4.06007C4.19039 4.16996 4.425 4.16996 4.61539 4.06007C4.80577 3.95018 4.92308 3.74702 4.92308 3.52711V2.91173H9.84616V3.52711C9.84616 3.74703 9.96346 3.95018 10.1539 4.06007C10.3442 4.16996 10.5789 4.16996 10.7692 4.06007C10.9596 3.95018 11.0769 3.74702 11.0769 3.52711V2.91173H13.5385V5.37327H1.23077V2.91173ZM13.5385 15.2194H1.23077V6.60404H13.5385V15.2194Z"
				fill={themeColor}
			/>
		</svg>
	);
};

export default Calendar2;
