import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const Pin: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor = (color && palette[color]?.main) || htmlColor || 'white';
	return (
		<svg
			width="23"
			height="23"
			viewBox="0 0 23 23"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M4.33994 18.4355L4.33984 17.4253L7.8755 13.8897L8.88574 14.8999L5.35007 18.4356L4.33994 18.4355Z"
				fill={themeColor}
			/>
			<path
				d="M12.9263 13.8898L13.6839 14.6474C14.0853 15.0495 14.3107 15.5944 14.3107 16.1627C14.3107 16.7307 14.0852 17.2757 13.6839 17.6779L13.1788 18.183L4.5923 9.59634L5.09731 9.09133C5.49949 8.68999 6.04456 8.46455 6.61256 8.46456C7.18078 8.46456 7.72573 8.68989 8.12781 9.09133L11.9159 12.8794C12.0554 13.019 12.2816 13.019 12.4212 12.8794C12.5606 12.74 12.5606 12.5138 12.421 12.3743L8.88565 8.83893L14.189 3.53557L14.694 4.04058C14.8335 4.18009 15.0597 4.18009 15.1991 4.04068C15.3387 3.90116 15.3387 3.67498 15.1991 3.53546L14.4415 2.77784C14.2406 2.57691 14.1277 2.30442 14.1277 2.02031C14.1277 1.73621 14.2406 1.46351 14.4415 1.26258L14.9465 0.757568L22.0179 7.82891L21.5129 8.33392C21.3119 8.53485 21.0392 8.64773 20.7551 8.64773C20.471 8.64773 20.1985 8.53485 19.9976 8.33392L16.2095 4.5458C16.07 4.40628 15.8438 4.40628 15.7043 4.5458C15.5649 4.68521 15.5649 4.9114 15.7044 5.05092L19.2398 8.58631L13.9364 13.8897L13.4313 13.3846L13.4314 13.3847C13.2919 13.2452 13.0657 13.2452 12.9263 13.3846C12.7868 13.5241 12.7868 13.7503 12.9263 13.8898Z"
				fill={themeColor}
			/>
		</svg>
	);
};

export default Pin;
