import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const Close: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		htmlColor || (color && palette[color]?.main) || '#634B99';
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M21.1971 9.46387L16 14.661L10.8029 9.46387L9.46436 10.8024L14.6615 15.9995L9.46436 21.1966L10.8029 22.5351L16 17.338L21.1971 22.5351L22.5356 21.1966L17.3385 15.9995L22.5356 10.8024L21.1971 9.46387Z"
				fill={themeColor}
			/>
			<path
				d="M16 0C7.17747 0 0 7.17747 0 16C0 24.8225 7.17747 32 16 32C24.8225 32 32 24.8225 32 16C32 7.17747 24.8225 0 16 0ZM16 30.1067C8.22183 30.1067 1.89328 23.7782 1.89328 16C1.89328 8.22183 8.22183 1.89328 16 1.89328C23.7782 1.89328 30.1067 8.22183 30.1067 16C30.1067 23.7782 23.7782 30.1067 16 30.1067Z"
				fill={themeColor}
			/>
		</svg>
	);
};

export default Close;
