import React from 'react';

import Box, { BoxProps } from '@ui/components/Box';
import { Color } from '@ui/index';

import TabPanel, { TabPanelProps } from './TabPanel';
import TabLabel, { TabLabelProps } from './TabLabel';
import TabHeader, { TabHeaderProps } from './TabHeader';

export interface TabStep {
	label: string | number | React.ReactElement;
	component: React.ReactNode;
}

type TabsProps = {
	tabHeaderProps?: TabHeaderProps;
	id: string;
	activeIndex?: number;
	steps: Array<TabStep>;
	color?: Color;
	onIndexChange?: (index: number) => void;
	panelColor?:
		| 'primary'
		| 'secondary'
		| 'success'
		| 'info'
		| 'warning'
		| 'error';
} & Omit<BoxProps, 'ref' | 'children'>;

const Tabs: React.FC<TabsProps> = ({
	activeIndex,
	steps,
	id,
	tabHeaderProps,
	color,
	panelColor,
	onIndexChange,
	...other
}) => {
	const [value, setValue] = React.useState(activeIndex || 0);
	React.useEffect(() => {
		setValue(activeIndex || 0);
	}, [activeIndex]);
	const handleChange = React.useCallback(
		(_event: React.SyntheticEvent, newValue: any) => {
			setValue(newValue);
			if (onIndexChange) {
				onIndexChange(newValue);
			}
		},
		[onIndexChange],
	);

	return (
		<Box {...other}>
			<Box>
				<TabHeader
					{...(tabHeaderProps || {})}
					id={id}
					variant="fullWidth"
					value={value}
					onChange={handleChange}
					aria-label={`basic tabs ${id}`}
					color={color}
				>
					{steps.map((m, idx) => (
						<TabLabel
							key={`tab-label-${idx}`}
							label={m.label}
							tabId={id}
							index={idx}
						/>
					))}
				</TabHeader>
			</Box>
			{steps.map((m, idx) => (
				<TabPanel
					key={`tab-panel-${idx}`}
					value={value}
					tabId={id}
					index={idx}
					color={panelColor}
				>
					{m.component}
				</TabPanel>
			))}
		</Box>
	);
};

export { Tabs as default, TabLabel, TabHeader, TabPanel };
export type { TabLabelProps, TabHeaderProps, TabPanelProps };
