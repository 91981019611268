import React from 'react';

import { styled } from '@ui/styles';

import ContentColumn from '@web/components/layout/ContentColumn';
import BottomFooter from './Bottom';
import { BrandCode } from '@interfaces/index';
import dynamic from 'next/dynamic';
import BrandCodeContext from '@web/components/BrandCodeContext';

export const FooterContainer = styled('footer', {
	shouldForwardProp: prop => prop !== 'noFooterMargin',
})<{ noFooterMargin?: boolean }>(({ theme, noFooterMargin }) => ({
	display: 'flex',
	width: '100%',
	backgroundColor: '#fcfcfc',
	marginTop: noFooterMargin ? 0 : theme.spacing(10),
	paddingTop: theme.spacing(12.5),
	paddingLeft: theme.spacing(6),
	paddingRight: theme.spacing(6),
	[theme.breakpoints.down('sm')]: {
		paddingTop: theme.spacing(5.5),
		paddingLeft: 0,
		paddingRight: 0,
	},
	'@media print': {
		display: 'none',
		visibilty: 'hidden',
	},
	zIndex: 1,
	position: 'relative',
}));

type Props = {
	noFooterMargin?: boolean;
	noRequestBtn?: boolean;
	initBrandCode?: BrandCode;
};

const Default = dynamic(() => import('@web/components/layout/Footer/Default'));
const KYClinical = dynamic(
	() => import('@web/components/layout/Footer/KYClinical'),
);
const Della = dynamic(() => import('@web/components/layout/Footer/Della'));
const Nuaa = dynamic(() => import('@web/components/layout/Footer/Nuaa'));
const KY = dynamic(() => import('@web/components/layout/Footer/KY'));
const Amamente = dynamic(
	() => import('@web/components/layout/Footer/Amamente'),
);
const InstagramPosts = dynamic(
	() => import('@common-lib/modules/instagram/components/InstagramPosts'),
);

const Main: React.FC<{ brandCode?: BrandCode; noRequestBtn?: boolean }> = ({
	brandCode,
	noRequestBtn,
}) => {
	switch (brandCode) {
		case BrandCode.AMAMENTE:
			return <Amamente noRequestBtn={noRequestBtn} />;
		case BrandCode.DELLA:
			return <Della noRequestBtn={noRequestBtn} />;
		case BrandCode.KYCLINICAL:
			return <KYClinical noRequestBtn={noRequestBtn} />;
		case BrandCode.NUAA:
			return <Nuaa noRequestBtn={noRequestBtn} />;
		case BrandCode.KY:
			return <KY noRequestBtn={noRequestBtn} />;
		default:
			return <Default noRequestBtn={noRequestBtn} />;
	}
};
const Footer: React.FC<Props> = ({
	noFooterMargin,
	noRequestBtn,
	initBrandCode,
}) => {
	const brandCode = React.useContext(BrandCodeContext) || initBrandCode;
	return (
		<FooterContainer noFooterMargin={noFooterMargin} className="no-print">
			<ContentColumn maxWidth={1244}>
				{brandCode ? (
					<InstagramPosts brandCode={brandCode} pb={20} />
				) : null}
				<Main brandCode={brandCode} noRequestBtn={noRequestBtn} />
				<BottomFooter noTerms={noRequestBtn} />
			</ContentColumn>
		</FooterContainer>
	);
};

export default Footer;
