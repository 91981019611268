import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const Checked: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || palette.primary.main;
	return (
		<svg
			width="36"
			height="36"
			viewBox="0 0 36 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<circle cx="18" cy="18" r="18" fill={themeColor} />
			<circle cx="18" cy="18" r="16" fill="white" />
			<circle cx="18" cy="18" r="12" fill={themeColor} />
		</svg>
	);
};

export default Checked;
