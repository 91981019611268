import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const Arrow: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#9A4856';
	return (
		<svg
			width="17"
			height="16"
			viewBox="0 0 17 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17 8.77148L17 7.2286L3.07113 7.2286L9.20641 1.0918L8.1161 -7.76656e-07L0.117112 8.00051L8.1161 16L9.2069 14.9087L3.07112 8.77139L17 8.77148Z"
				fill={themeColor}
			/>
		</svg>
	);
};

export default Arrow;
