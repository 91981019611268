import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const CircleAdd: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#9A4856';
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
				fill={themeColor}
			/>
			<path
				d="M4.04004 9.98926L16.4598 10.0111"
				stroke="white"
				strokeWidth="2"
			/>
			<path
				d="M10.2393 16.21L10.2611 3.79024"
				stroke="white"
				strokeWidth="2"
			/>
		</svg>
	);
};

export default CircleAdd;
