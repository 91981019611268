import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { LinearProgressProps as IProps } from './linear-progress';

export type LinearProgressProps = IProps;

const TheLinearProgress: React.FC<LinearProgressProps> = ({
	withLabel,
	...props
}) => {
	if (!withLabel) {
		return <LinearProgress {...props} />;
	}
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Box sx={{ width: '100%', mr: 1 }}>
				<LinearProgress variant="determinate" {...props} />
			</Box>
			<Box sx={{ minWidth: 35 }}>
				<Typography variant="body2" color="text.secondary">
					{`${Math.round(props.value || 0)}%`}
				</Typography>
			</Box>
		</Box>
	);
};

export default TheLinearProgress;
