type Option = {
	style: 'percent';
	minimumFractionDigits: number;
	maximumFractionDigits: number;
};

const defaultOptions = {
	style: 'percent',
	minimumFractionDigits: 0,
	maximumFractionDigits: 2,
};

const defaultFormatter = new Intl.NumberFormat('pt-BR', defaultOptions);

const format =
	(formatter: any) =>
	(el: number): string | null | undefined => {
		if (el === null || el === undefined) {
			return el;
		}
		if (Number.isNaN(el)) {
			return null;
		}
		const num = el / 100;
		return formatter.format(num);
	};

const percentageFormat = (el: number): string | null | undefined =>
	format(defaultFormatter)(el);

const customFormat =
	(options: Option) =>
	(el: number): string | null | undefined => {
		const formatter = new Intl.NumberFormat('pt-BR', {
			...defaultOptions,
			...options,
		});
		return format(formatter)(el);
	};

export { percentageFormat as default, customFormat };
