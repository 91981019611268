import Tabs, { TabsProps } from '@mui/material/Tabs';

import { Color } from '@ui/index';
import { styled } from '@ui/styles';

export type TabHeaderProps = TabsProps & {
	id: string;
	color?: Color;
};

const TabHeader = styled(Tabs, {
	shouldForwardProp: prop => prop !== 'color',
})<TabHeaderProps>(({ theme, color }) => ({
	backgroundColor: color ? theme.palette[color].main : undefined,
	'&& .MuiButtonBase-root, &&.Mui-selected': {
		color: color ? theme.palette[color].contrastText : undefined,
	},
	'& .MuiTabs-indicator': {
		backgroundColor: color ? theme.palette[color].contrastText : undefined,
	},
}));

export default TabHeader;
