import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const Target: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#9A4856';
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M23.4238 11.4238H20.4738C20.1894 7.19763 16.8019 3.81056 12.5758 3.52575V0.575775C12.5758 0.257607 12.3182 0 12 0C11.6818 0 11.4242 0.257607 11.4242 0.575775V3.52575C7.19807 3.81012 3.811 7.19763 3.52618 11.4238H0.575775C0.257607 11.4238 0 11.6814 0 11.9996C0 12.3177 0.257607 12.5753 0.575775 12.5753H3.52575C3.81012 16.8015 7.19807 20.1886 11.4238 20.4734V23.4233C11.4238 23.7415 11.6814 23.9991 11.9996 23.9991C12.3177 23.9991 12.5753 23.7411 12.5753 23.4233V20.4734C16.8015 20.189 20.1886 16.8011 20.4734 12.5753H23.4233C23.7415 12.5753 23.9991 12.3177 23.9991 11.9996C23.9991 11.6814 23.7411 11.4238 23.4233 11.4238H23.4238ZM19.3148 11.4238H12.5762V4.6852C16.166 4.96606 19.0344 7.83397 19.3148 11.4238ZM11.4242 4.6852V11.4238H4.68563C4.9665 7.83397 7.83441 4.96562 11.4242 4.6852ZM4.68563 12.5758H11.4242V19.3144C7.83441 19.0335 4.96606 16.1656 4.68563 12.5758ZM12.5762 19.3144V12.5758H19.3148C19.0339 16.1656 16.166 19.0339 12.5762 19.3144Z"
				fill={themeColor}
			/>
		</svg>
	);
};

export default Target;
