import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const RightArrow: React.FC<IconProps & { rounded?: boolean }> = ({
	color,
	htmlColor,
	rounded = true,
	...rest
}) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#634B99';
	const bgColor = htmlColor
		? '#ffffff'
		: (color && palette[color]?.lighter) || '#ffffff';
	return (
		<svg
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<rect
				width="40"
				height="40"
				rx={rounded ? '20' : '0'}
				fill={bgColor}
			/>
			<path
				d="M8 19.2285L8 20.7714L28.9288 20.7714L22.7935 26.9082L23.8838 28L31.8828 19.9995L23.8838 12L22.793 13.0913L28.9288 19.2286L8 19.2285Z"
				fill={themeColor}
			/>
		</svg>
	);
};

export default RightArrow;
