import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const Print: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor = (color && palette[color]?.dark) || htmlColor || '#fff';
	const themeColor2 =
		(color && palette[color]?.lighter) || htmlColor || '#634B99';
	return (
		<svg
			width="60"
			height="60"
			viewBox="0 0 60 60"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<rect
				x="60"
				y="60"
				width="60"
				height="60"
				rx="30"
				transform="rotate(-180 60 60)"
				fill={themeColor2}
			/>
			<path
				d="M40.4946 23.7698H35.0823L35.0824 20H24.4124V23.7698H19V36.194H24.1546V40.601H35.3402V36.194H40.4948L40.4946 23.7698ZM25.9586 21.593H33.5359V23.77L25.9586 23.7698V21.593ZM25.7008 39.0347V32.3978H33.7935V39.0082L25.7008 39.0347ZM38.9482 34.6011H35.34L35.3398 32.3977H37.2212V30.8047L22.2728 30.8049V32.3979H24.1542V34.6013H20.546L20.5459 25.3628H38.9479L38.9482 34.6011Z"
				fill={themeColor}
			/>
			<path
				d="M35.5195 26.5039H37.839V28.0969H35.5195V26.5039Z"
				fill={themeColor}
			/>
			<path
				d="M27.7637 33.5659H31.707V35.1589H27.7637V33.5659Z"
				fill={themeColor}
			/>
			<path
				d="M26.9902 36.2207H32.4799V37.8137H26.9902V36.2207Z"
				fill={themeColor}
			/>
		</svg>
	);
};

export default Print;
