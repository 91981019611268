/* eslint-disable react/no-children-prop */
import React from 'react';
import MUIBadge from '@mui/material/Badge';

import { styled } from '@ui/styles';

import { BadgeProps as IProps } from './badge';

export type BadgeProps = IProps;

const Badge = styled((props: BadgeProps) => <MUIBadge {...props} />)(() => ({
	position: 'relative',
}));

const TheBadge: React.ForwardRefRenderFunction<HTMLDivElement, BadgeProps> = (
	props,
	ref,
) => {
	const { width, fullWidth, ...rest } = props;
	return (
		<Badge
			components={{ Root: 'div' }}
			{...{
				...rest,
				ref,
				width: fullWidth ? '100%' : width,
			}}
		/>
	);
};

export default React.memo(React.forwardRef(TheBadge));
