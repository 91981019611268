import React from 'react';

import useTheme from '@ui/styles/hooks/useTheme';

import { IconProps } from './props';

const Question: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#E19017';
	return (
		<svg
			width="17"
			height="17"
			viewBox="0 0 17 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.948242 8.5C0.948242 4.08172 4.52996 0.5 8.94824 0.5V0.5C13.3665 0.5 16.9482 4.08172 16.9482 8.5V8.5C16.9482 12.9183 13.3665 16.5 8.94824 16.5V16.5C4.52996 16.5 0.948242 12.9183 0.948242 8.5V8.5Z"
				fill={themeColor}
			/>
			<path
				d="M9.05652 3.97607C9.89386 3.97607 10.5807 4.17887 11.1172 4.58446C11.6536 4.98569 11.9218 5.50249 11.9218 6.13486C11.9218 6.35728 11.8869 6.57316 11.8171 6.78249C11.7474 6.98747 11.6427 7.18372 11.5031 7.37125C11.3679 7.55442 11.2436 7.70924 11.1303 7.83572C11.0212 7.95783 10.8751 8.10611 10.692 8.28056C10.5131 8.45064 10.3932 8.56622 10.3322 8.62727C10.0879 8.88458 9.96582 9.14407 9.96582 9.40574V9.47116C9.96582 9.82878 9.75648 10.0076 9.33781 10.0076H8.60513C8.19082 10.0076 7.98367 9.82878 7.98367 9.47116V9.40574C7.98367 8.97399 8.19518 8.54005 8.61822 8.10393C8.688 8.02107 8.84282 7.87061 9.08268 7.65255C9.32255 7.43449 9.51008 7.23824 9.64527 7.06379C9.78047 6.88498 9.84807 6.71271 9.84807 6.54699C9.84807 6.32457 9.75867 6.14576 9.57986 6.01056C9.40105 5.87101 9.159 5.80123 8.85372 5.80123C8.62258 5.80123 8.39798 5.86665 8.17992 5.99748C7.96186 6.12396 7.79832 6.2984 7.68929 6.52082C7.50612 6.85663 7.22482 6.94604 6.8454 6.78904L6.33514 6.56007C5.97316 6.41179 5.88376 6.13704 6.06693 5.73581C6.28935 5.22555 6.67095 4.8047 7.21174 4.47325C7.75252 4.1418 8.36745 3.97607 9.05652 3.97607ZM8.05562 10.9234C8.30421 10.6792 8.59859 10.5571 8.93876 10.5571C9.27894 10.5571 9.57113 10.6792 9.81536 10.9234C10.0596 11.1633 10.1817 11.4511 10.1817 11.7869C10.1817 12.1184 10.0574 12.4084 9.80882 12.657C9.56459 12.9012 9.27457 13.0233 8.93876 13.0233C8.59859 13.0233 8.30421 12.9012 8.05562 12.657C7.8114 12.4084 7.68929 12.1184 7.68929 11.7869C7.68929 11.4511 7.8114 11.1633 8.05562 10.9234Z"
				fill="white"
			/>
		</svg>
	);
};

export default Question;
