import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const Instagram: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor = (color && palette[color]?.main) || htmlColor || 'black';
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M13.034 0H4.96598C2.22337 0 0 2.22337 0 4.96598V13.034C0 15.7766 2.22337 18 4.96598 18H13.034C15.7766 18 18 15.7766 18 13.034V4.96598C18 2.22337 15.7766 0 13.034 0ZM16.4024 13.034C16.4024 14.8979 14.8846 16.4024 13.034 16.4024H4.96598C3.10207 16.4024 1.59763 14.8846 1.59763 13.034V4.96598C1.59763 3.11538 3.11538 1.59763 4.96598 1.59763H13.034C14.8979 1.59763 16.4024 3.11538 16.4024 4.96598V13.034Z"
				fill={themeColor}
			/>
			<path
				d="M8.99996 4.36694C6.44375 4.36694 4.35352 6.44387 4.35352 9.00008C4.35352 11.5563 6.43044 13.6465 8.99996 13.6465C11.5562 13.6465 13.6464 11.5696 13.6464 9.00008C13.6464 6.44387 11.5562 4.36694 8.99996 4.36694ZM8.99996 12.0489C7.32245 12.0489 5.95115 10.6909 5.95115 9.01339C5.96446 7.32256 7.32245 5.96458 8.99996 5.96458C10.6775 5.96458 12.0488 7.32256 12.0488 9.00008C12.0488 10.6776 10.6775 12.0489 8.99996 12.0489Z"
				fill={themeColor}
			/>
			<path
				d="M13.8327 3.00879C13.5265 3.00879 13.2203 3.12861 13.0073 3.35494C12.7943 3.56796 12.6611 3.87417 12.6611 4.18039C12.6611 4.4866 12.781 4.79281 13.0073 5.00583C13.2203 5.21885 13.5265 5.35198 13.8327 5.35198C14.1389 5.35198 14.4452 5.23216 14.6582 5.00583C14.8712 4.79281 15.0043 4.4866 15.0043 4.18039C15.0043 3.87417 14.8845 3.56796 14.6582 3.35494C14.4452 3.12861 14.1523 3.00879 13.8327 3.00879Z"
				fill={themeColor}
			/>
		</svg>
	);
};

export default Instagram;
