import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const Hamburger: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#634B99';
	return (
		<svg
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<rect width="40" height="40" rx="8" fill={themeColor} />
			<rect x="10" y="12" width="20" height="2" fill="white" />
			<rect x="10" y="19" width="20" height="2" fill="white" />
			<rect x="10" y="26" width="20" height="2" fill="white" />
		</svg>
	);
};

export default Hamburger;
