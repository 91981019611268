import React from 'react';
import FormControlLabel, {
	FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import MUIFormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import Checked from '@ui/icons/Checked';
import { styled } from '@ui/styles';
import UnChecked from '@ui/icons/UnChecked';

export type CheckBoxProps = CheckboxProps &
	Omit<FormControlLabelProps, 'control' | 'label'> & {
		noWrap?: boolean;
		error?: boolean;
		fullWidth?: boolean;
		helperText?: string | number | React.ReactElement;
		label?: string | number | React.ReactElement;
	};

const FormControl = styled(MUIFormControl)(({ theme }) => ({
	marginTop: theme.spacing(1),
	marginLeft: 2,
}));

const CheckBox: React.FC<React.PropsWithChildren<CheckBoxProps>> = ({
	children,
	label,
	id,
	disabled,
	error,
	fullWidth,
	name,
	color,
	size,
	helperText,
	disableTypography,
	sx,
	...rest
}) => {
	return (
		<FormControl error={error} sx={sx} fullWidth={fullWidth}>
			<FormControlLabel
				{...{
					id: id || name,
					disabled,
					name,
					disableTypography,
					...rest,
				}}
				label={label || (children as any)}
				control={
					<Checkbox
						{...{ id: id || name, disabled, name, ...rest }}
						color={color || 'primary'}
						size={size || 'small'}
						checkedIcon={<Checked />}
						icon={<UnChecked />}
					/>
				}
			/>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
};

export default CheckBox;
