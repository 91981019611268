import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormLabel, { FormLabelProps } from '@mui/material/FormLabel';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { IconButtonProps } from '@mui/material/IconButton';
import FormHelperText from '@mui/material/FormHelperText';

import Checked from '@ui/icons/Checked';
import UnChecked from '@ui/icons/UnChecked';
import QuestionIcon from '@ui/icons/Question';
import Tooltip from '@ui/components/Tooltip';
import { styled } from '@ui/styles';

export type BooleanInputProps = FormControlProps & {
	error?: boolean;
	fullWidth?: boolean;
	disabled?: boolean;
	isRequired?: boolean;
	value?: boolean;
	helperText?: string | number | React.ReactElement;
	label?: string | number | React.ReactElement;
	yesLabel?: string | number | React.ReactElement;
	noLabel?: string | number | React.ReactElement;
	color?:
		| 'primary'
		| 'secondary'
		| 'error'
		| 'info'
		| 'success'
		| 'warning'
		| 'default';
	size?: 'small' | 'medium';
	onChange: (e: boolean) => void;
	onBlur?: (e?: any) => void;
	info?: string;
};
const iconProps: Omit<IconButtonProps, 'onClick'> = {
	size: 'small',
	sx: { mt: -0.3 },
};
const Label = styled(props => (
	<FormLabel {...props} component="legend" />
))<FormLabelProps>(({ theme }) => ({
	marginLeft: 0,
}));
const Control = styled(props => <FormControl {...props} />)<FormControlProps>(
	({ theme }) => ({
		marginLeft: 4,
	}),
);
const BooleanInput: React.FC<BooleanInputProps> = ({
	children,
	label,
	disabled,
	error,
	fullWidth,
	color,
	size,
	helperText,
	onChange,
	onBlur,
	value,
	noLabel,
	yesLabel,
	info,
	isRequired,
}) => {
	const handleChange = React.useCallback(
		(value: boolean) => (event: React.ChangeEvent<HTMLInputElement>) => {
			if (!disabled) {
				onChange(value);
			}
			if (onBlur) onBlur(event);
		},
		[onChange, disabled],
	);
	return (
		<Control disabled={disabled} error={error} fullWidth={fullWidth}>
			<Label>
				{label || children}
				{isRequired ? '*' : ''}
				{info ? (
					<Tooltip
						iconButtonProps={iconProps}
						controlled
						title="Help text"
						isIcon
					>
						<QuestionIcon />
					</Tooltip>
				) : null}
			</Label>
			<FormGroup row>
				<FormControlLabel
					sx={{ minWidth: 142 }}
					label={yesLabel || 'Sim'}
					labelPlacement="end"
					control={
						<Checkbox
							checkedIcon={<Checked />}
							icon={<UnChecked />}
							checked={value === true}
							onChange={handleChange(true)}
							color={color || 'primary'}
							size={size || 'small'}
						/>
					}
				/>
				<FormControlLabel
					label={noLabel || 'Não'}
					labelPlacement="end"
					control={
						<Checkbox
							checkedIcon={<Checked />}
							icon={<UnChecked />}
							checked={value === false}
							onChange={handleChange(false)}
							color={color || 'primary'}
							size={size || 'small'}
						/>
					}
				/>
			</FormGroup>
			<FormHelperText>{helperText}</FormHelperText>
		</Control>
	);
};

export default BooleanInput;
