/* eslint-disable react/no-children-prop */
import React from 'react';
import CardContent from '@mui/material/CardContent';

import { CardContentProps as IProps } from './card-content';

export type CardContentProps = IProps;
const TheCardContent: React.ForwardRefRenderFunction<
	HTMLDivElement,
	CardContentProps
> = (props, ref) => {
	return (
		<CardContent
			{...{
				...props,
				ref,
			}}
		/>
	);
};

export default React.memo(React.forwardRef(TheCardContent));
