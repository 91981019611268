import React from 'react';

type ImgProps = {
	width?: string | number;
	height?: string | number;
};
const Img: React.FC<ImgProps> = ({ width = 210, height = 210 }) => (
	<svg height={height} width={width} xmlns="http://www.w3.org/2000/svg">
		<symbol id="400_image" width="210" height="210" viewBox="0 0 210 210">
			<circle cx="105.202" cy="105.261" r="40" stroke="E1E1E1" />
			<circle
				// opacity="0.8"
				cx="105.202"
				cy="105.261"
				r="72"
				fill="#ffffff"
				stroke="#E1E1E1"
			/>
			<circle
				opacity="0.5"
				cx="105.202"
				cy="105.261"
				r="104"
				fill="#ffffff"
				stroke="#E1E1E1"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M122.588 129.761C142.746 129.761 159.088 113.42 159.088 93.2615C159.088 73.1031 142.746 56.7615 122.588 56.7615C102.43 56.7615 86.088 73.1031 86.088 93.2615C86.088 113.42 102.43 129.761 122.588 129.761Z"
				fill="url(#paint0_linear_1740_599)"
			/>
			<g filter="url(#filter0_f_1740_599)">
				<ellipse
					cx="77.8679"
					cy="140.897"
					rx="10.6666"
					ry="0.639996"
					fill="#A6A4A4"
				/>
			</g>
			<g filter="url(#filter1_f_1740_599)">
				<ellipse
					cx="102.21"
					cy="140.897"
					rx="10.6666"
					ry="0.639996"
					fill="#A6A4A4"
				/>
			</g>
			<rect
				x="61.8955"
				y="93.2615"
				width="17.3302"
				height="16.2834"
				fill="#00BCC1"
			/>
			<rect
				x="84.0249"
				y="93.2615"
				width="17.3302"
				height="16.2834"
				fill="#00BCC1"
			/>
			<rect
				x="61.8955"
				y="113.478"
				width="17.3302"
				height="16.2834"
				fill="#00BCC1"
			/>
			<rect
				x="84.0249"
				y="113.478"
				width="17.3302"
				height="16.2834"
				fill="#00BCC1"
			/>
			<path
				d="M115.215 86.2655C115.023 86.2015 114.927 86.0628 114.927 85.8495C114.927 85.7215 114.938 85.6255 114.959 85.5615C115.642 83.9828 116.687 82.7775 118.095 81.9455C119.503 81.1135 121.178 80.6975 123.119 80.6975C124.655 80.6975 126.021 80.9535 127.215 81.4655C128.41 81.9561 129.327 82.6281 129.967 83.4815C130.629 84.3348 130.959 85.2735 130.959 86.2975C130.959 87.0868 130.81 87.8015 130.511 88.4415C130.213 89.0601 129.85 89.5935 129.423 90.0415C128.997 90.4895 128.431 91.0228 127.727 91.6415C126.703 92.5375 125.935 93.3375 125.423 94.0415C124.911 94.7455 124.655 95.6095 124.655 96.6335C124.655 96.8041 124.591 96.9535 124.463 97.0815C124.335 97.1881 124.186 97.2415 124.015 97.2415H121.391C120.943 97.2415 120.719 96.9961 120.719 96.5055C120.719 95.5241 120.869 94.6388 121.167 93.8495C121.487 93.0601 121.861 92.3988 122.287 91.8655C122.735 91.3108 123.301 90.6921 123.983 90.0095C124.73 89.2415 125.263 88.6228 125.583 88.1535C125.925 87.6628 126.095 87.1295 126.095 86.5535C126.095 85.8068 125.807 85.2095 125.231 84.7615C124.677 84.2921 123.962 84.0575 123.087 84.0575C122.234 84.0575 121.434 84.3028 120.687 84.7935C119.941 85.2628 119.407 85.9028 119.087 86.7135C119.002 86.8841 118.917 86.9908 118.831 87.0335C118.746 87.0548 118.607 87.0548 118.415 87.0335L115.215 86.2655ZM125.327 103.993C125.327 104.271 125.253 104.473 125.103 104.601C124.975 104.708 124.762 104.761 124.463 104.761H120.655C120.399 104.761 120.218 104.708 120.111 104.601C120.026 104.495 119.983 104.324 119.983 104.089V100.121C119.983 99.7375 120.154 99.5455 120.495 99.5455H124.879C125.178 99.5455 125.327 99.7161 125.327 100.057V103.993Z"
				fill="white"
			/>
			<defs>
				<filter
					id="filter0_f_1740_599"
					x="63.2013"
					y="136.257"
					width="29.3333"
					height="9.28003"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feGaussianBlur
						stdDeviation="2"
						result="effect1_foregroundBlur_1740_599"
					/>
				</filter>
				<filter
					id="filter1_f_1740_599"
					x="87.5435"
					y="136.257"
					width="29.3333"
					height="9.28003"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feGaussianBlur
						stdDeviation="2"
						result="effect1_foregroundBlur_1740_599"
					/>
				</filter>
				<linearGradient
					id="paint0_linear_1740_599"
					x1="70.7395"
					y1="86.6583"
					x2="115.734"
					y2="143.875"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FEF6EA" />
					<stop offset="1" stopColor="#BF291C" />
				</linearGradient>
			</defs>
		</symbol>
		<use href="#400_image" x="0" y="0" height={height} width={width} />
	</svg>
);

Img.displayName = '400';

export default Img;
