import React from 'react';
import FormLabel from '@mui/material/FormLabel';
import MUIFormControl from '@mui/material/FormControl';
import {
	DateTimePicker,
	DateTimePickerProps,
} from '@mui/x-date-pickers/DateTimePicker';

import { styled } from '@ui/styles';

export type DateTimeInputProps = React.PropsWithChildren<
	DateTimePickerProps<never> & {
		variant?: any;
		error?: boolean;
		fullWidth?: boolean;
		isRequired?: boolean;
		helperText?: string;
	}
>;
const FormControl = styled(MUIFormControl)(({ theme, error }) => ({
	'& fieldset': {
		borderColor: `${
			error ? theme.palette.error?.main : theme.palette.info.light
		} !important`,
	},
}));
const DateTimeInput: React.FC<DateTimeInputProps> = ({
	error,
	label,
	fullWidth,
	children,
	isRequired,
	...rest
}) => {
	return (
		<FormControl error={error} fullWidth={fullWidth}>
			<FormLabel className="input-label">
				{label || children}
				{isRequired ? '*' : ''}
			</FormLabel>
			<DateTimePicker
				{...rest}
				// renderInput={params => (
				// 	<TextField
				// 		{...params}
				// 		error={error}
				// 		fullWidth={fullWidth}
				// 		helperText={helperText}
				// 	/>
				// )}
			/>
		</FormControl>
	);
};
export default DateTimeInput;
