import React from 'react';
import { SxProps, Theme } from '@mui/material/styles';

import Box, { BoxProps } from '@ui/components/Box';

import ErrorBoundary from '@common-lib/components/ErrorBoundary';
import BrandCodeContext from '../BrandCodeContext';
import ContainerConditon from '../ContainerConditon';
import ContentConditon from '../ContentConditon';

type Props = {
	hiddenBar?: boolean;
	noContainer?: boolean;
	contentColumn?: boolean;
	usePx?: boolean;
	haveTopInfo?: boolean;
	contentSx?: SxProps<Theme> | undefined;
} & Omit<BoxProps, 'ref'>;

const PageContent: React.FC<Props> = ({
	children = null,
	noContainer = true,
	contentColumn = false,
	sx,
	contentSx,
	hiddenBar,
	pt: top,
	usePx,
	haveTopInfo,
	...props
}) => {
	const px = usePx ? 2 : undefined;
	const pt = typeof top !== 'undefined' ? top : haveTopInfo ? 24 : 20;
	return (
		<ContainerConditon noContainer={noContainer}>
			<Box
				pt={hiddenBar ? 20 : pt}
				component="main"
				sx={{
					minHeight: '100vh',
					position: 'relative',
					overflow: 'hidden',
				}}
				{...props}
			>
				<Box
					sx={{
						minHeight: '100vh',
						...sx,
					}}
					px={px}
				>
					<ContentConditon
						contentColumn={contentColumn}
						contentSx={contentSx}
					>
						<ErrorBoundary>{children}</ErrorBoundary>
					</ContentConditon>
				</Box>
			</Box>
		</ContainerConditon>
	);
};

export default PageContent;
