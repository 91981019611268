import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const User: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#ffffff';
	return (
		<svg
			width="15"
			height="21"
			viewBox="0 0 15 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M11.5562 5.63426C11.5562 8.12664 9.69685 10.0185 7.55615 10.0185C5.41545 10.0185 3.55615 8.12664 3.55615 5.63426C3.55615 3.14188 5.41545 1.25 7.55615 1.25C9.69685 1.25 11.5562 3.14188 11.5562 5.63426Z"
				stroke={themeColor}
				strokeWidth="2"
			/>
			<path
				d="M13.7789 14.6643C13.7789 15.7627 13.1834 16.836 12.0641 17.6695C10.9449 18.5031 9.35195 19.0485 7.54938 19.0485C5.74681 19.0485 4.15389 18.5031 3.03462 17.6695C1.91539 16.836 1.31982 15.7627 1.31982 14.6643C1.31982 13.5658 1.91539 12.4926 3.03462 11.6591C4.15389 10.8255 5.74681 10.28 7.54938 10.28C9.35195 10.28 10.9449 10.8255 12.0641 11.6591C13.1834 12.4926 13.7789 13.5658 13.7789 14.6643Z"
				stroke={themeColor}
				strokeWidth="2"
			/>
		</svg>
	);
};

export default User;
