import React from 'react';

import { useTheme, styled } from '@ui/styles';

import { IconProps } from './props';

const SVG = styled('svg', {
	shouldForwardProp: prop => prop !== 'themeColor',
})<{ themeColor: string }>(({ themeColor }) => ({
	border: `2px solid ${themeColor}`,
	borderRadius: 8,
}));

const UnChecked: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.contrastText) ||
		htmlColor ||
		palette.primary.contrastText;
	const themeColor2 =
		(color && palette[color]?.main) || htmlColor || palette.primary.main;
	return (
		<SVG
			version="1.2"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 26 26"
			width="26"
			height="26"
			themeColor={themeColor2}
			{...rest}
		>
			<defs>
				<clipPath clipPathUnits="userSpaceOnUse" id="cp1">
					<path d="m-48-442h375v667h-375z" />
				</clipPath>
			</defs>
			<g clipPath="url(#cp1)">
				<path fill={themeColor} d="m1 1h23.7v23.8h-23.7z" />
				{/* <path
					fill={themeColor}
					d="m11.7 20c-0.5 0-0.9-0.2-1.3-0.6l-4.9-5.7c-0.6-0.7-0.5-1.8 0.2-2.4 0.7-0.7 1.8-0.6 2.4 0.1l3.6 4.1 7-9.3c0.6-0.8 1.7-0.9 2.4-0.3 0.8 0.5 0.9 1.6 0.3 2.4l-8.3 11c-0.3 0.4-0.8 0.7-1.3 0.7q0 0-0.1 0z"
				/> */}
			</g>
		</SVG>
	);
};

export default UnChecked;
