import React from 'react';

import Box, { BoxProps } from '@ui/components/Box';

type Props = Omit<BoxProps, 'ref'>;

const ContentColumn: React.FC<Props> = ({
	children,
	maxWidth,
	py,
	px,
	mx,
	...props
}) => (
	<Box
		maxWidth={maxWidth || 1440}
		py={py !== undefined ? py : 2}
		px={px !== undefined ? px : 2}
		mx={typeof mx === 'undefined' ? 'auto' : mx}
		width="100%"
		height="100%"
		// flexGrow={1}
		// alignItems="stretch"
		// display="flex"
		{...props}
	>
		{children}
	</Box>
);

export default ContentColumn;
