import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const GoogleMapMarker: React.FC<IconProps> = ({
	color,
	htmlColor,
	...rest
}) => {
	const palette: any = useTheme().palette;
	const themeColor =
		htmlColor || (color && palette[color]?.main) || '#F29FC5';
	return (
		<svg
			width="16"
			height="20"
			viewBox="0 0 16 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.11326 17.6587C8.01638 17.7688 7.89892 17.7854 7.83691 17.7854C7.77604 17.7854 7.65873 17.7688 7.56128 17.6587C5.95406 15.8283 2.21485 11.2075 2.21485 7.83712C2.21485 4.73688 4.73725 2.21451 7.83745 2.21451C10.9378 2.21451 13.4601 4.73691 13.4601 7.83712C13.4601 11.2077 9.72092 15.8282 8.1129 17.6587M7.83712 0C3.51564 0 0 3.51546 0 7.83712C0 11.8122 3.69488 16.6129 5.89768 19.1204C6.38865 19.6795 7.09566 20 7.83639 20C8.57825 20 9.28526 19.6795 9.77637 19.1204C11.9787 16.613 15.6741 11.8122 15.6741 7.83712C15.6741 3.51564 12.1585 0 7.83694 0"
				fill={themeColor}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.83754 5.79639C6.60127 5.79639 5.59863 6.79848 5.59863 8.0353C5.59863 9.27157 6.60127 10.2736 7.83754 10.2736C9.07382 10.2736 10.0765 9.27153 10.0765 8.0353C10.0765 6.79848 9.07382 5.79639 7.83754 5.79639Z"
				fill={themeColor}
			/>
		</svg>
	);
};

export default GoogleMapMarker;
